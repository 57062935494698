import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory } from "react-router-dom"

import ViewOrderItemsModal from "./ViewOrderItemsModal"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { GetPurchaseOrdersItems } from "../../../api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { AutoComplete } from "components/AutoComplete"
import { convertToSelectOptions, getAppName } from "utils/commonUtils"
import { Refresh } from "components/Refresh"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { PurchaseOrderStatus } from "../../../constants"
import customStyles from "../SelectStyles"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { TableTopHeading } from "components/TableTopHeading"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    RECEIVED_QUANTITY: "Received Qty.",
    REQUEST_QUANTITY: "Requested Qty.",
    SUBSTITUTE_ITEM: "Substitute Name",
    STATUS: "Status",
    ACTIONS: "Actions",
}

const dataKey = "data"
const totalKey = "total"

const PurchaseOrderItemsListing = ({}) => {
    const history = useHistory()
    const { poId } = useParams()

    const [statusFilters, setStatusFilters] = useState(null)
    const [modalToggle, setModalToggle] = useState(false)
    const [selectedData, setSelectedData] = useState([])

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        wholeResponse,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetPurchaseOrdersItems,
        params: {
            ...(statusFilters?.value && {
                status: statusFilters?.value,
            }),
        },
        deps: [statusFilters],
        dataKey: dataKey,
        totalKey: totalKey,
        id: poId,
    })

    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleModalToggle = () => {
        setModalToggle(!modalToggle)
    }

    return (
        <React.Fragment>
            <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3">
                <BackButtonTableTop />
            </div>
            <TableTopHeading
                title={`Request Management (${getAppName()})`}
                className="me-4 top-heading-typo"
                iconImage={InventoryRequestIcon}
                iconClass="inventory-req-icon"
            />
            <div className="gt-business-accounts">
                <Container fluid>
                    <Row>
                        <Col className="col-12 px-lg-3 px-2 order-management-con">
                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <h5 className="fw-600 mb-0 table-main-heading">
                                                ORDER NO: {poId}
                                            </h5>

                                            <h5 className="fw-600 mb-0 table-main-heading mt-1">
                                                PURCHASE ORDER NO. :{" "}
                                                {isFetching
                                                    ? "..."
                                                    : wholeResponse?.poNo}
                                            </h5>
                                        </div>

                                        <p className="fw-500">
                                            Order Date:{" "}
                                            {convertDateTime({
                                                date: data[0]?.createdAt,
                                                customFormat:
                                                    dateTimeFormat.ddmmyyyy,
                                                dateOnly: true,
                                            })}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                        <div className="d-flex flex-wrap gap-2">
                                            <div
                                            // style={{ width: "200px" }}
                                            // className="me-2"
                                            >
                                                <AutoComplete
                                                    customStyles={
                                                        customStyles.WarehouseIM
                                                    }
                                                    value={statusFilters}
                                                    isClearable
                                                    onChange={val =>
                                                        handleSelectStatusOption(
                                                            val
                                                        )
                                                    }
                                                    isSearchable={false}
                                                    placeholder="Select status"
                                                    classNamePrefix="status-header-search"
                                                    options={convertToSelectOptions(
                                                        PurchaseOrderStatus
                                                    )}
                                                    // className="w-100 order-man-select"
                                                />
                                            </div>
                                            <SearchBox
                                                isHideLable
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                inputClasses="table-main-search-input wh-im-search-input"
                                                placeholder="Search..."
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.SKU_NO,
                                                render: data => (
                                                    <span>{data.skuNo}</span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.ITEM_NAME,
                                                render: data => (
                                                    <span>{data.itemName}</span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.REQUEST_QUANTITY,
                                                render: data => (
                                                    <span>{data.quantity}</span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.RECEIVED_QUANTITY,
                                                render: data => (
                                                    <span>
                                                        {Math.abs(
                                                            data.quantity -
                                                                data.quantityRemaining
                                                        )}
                                                    </span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.SUBSTITUTE_ITEM,
                                                render: data =>
                                                    data?.substitute
                                                        ?.itemName || "-",
                                            },

                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${PurchaseOrderStatus.getLabelClass(
                                                                data.status
                                                            )}`}
                                                        >
                                                            {PurchaseOrderStatus.getDisplayTextKey(
                                                                data.status
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center d-flex justify-content-center align-items-center">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment ln-height-1"
                                    title="Back to Main"
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    isOpen={modalToggle}
                    handleModalToggling={handleModalToggle}
                    bodyClassName="p-0"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="pb-0"
                    className="inv-req-popup-viewdetails"
                    sizeClasses="10"
                >
                    <ViewOrderItemsModal
                        modalCloseHandler={handleModalToggle}
                        data={selectedData}
                    />
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default PurchaseOrderItemsListing
