import { injectBaseConstantMethods } from "./BaseConstants"

const EntityType = {
  USER: "user",
  BOX: "box",
  ORDER: "order",
  GYFT: "gyft",
  BUSINESS_ACCOUNT: "business_account",
}

const displayTextKeys = {}

const labelClass = {}

export default injectBaseConstantMethods(
  EntityType,
  displayTextKeys,
  labelClass
)
