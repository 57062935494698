import { injectBaseConstantMethods } from "./BaseConstants"

const GyftStatusConstants = {
  IN_TRANSIT: "in_transit",
  DELIVERED: "delivered",
  PENDING: "pending",
  CANCELLED: "canceled",
}

const displayTextKeys = {
  [GyftStatusConstants.IN_TRANSIT]: "In Transit",
  [GyftStatusConstants.DELIVERED]: "Delivered",
  [GyftStatusConstants.PENDING]: "Pending",
  [GyftStatusConstants.CANCELLED]: "Cancelled",
}

const labelClass = {
  [GyftStatusConstants.IN_TRANSIT]: "intransit",
  [GyftStatusConstants.DELIVERED]: "delivered",
  [GyftStatusConstants.PENDING]: "pending",
  [GyftStatusConstants.CANCELLED]: "cancelled",
}

export default injectBaseConstantMethods(
  GyftStatusConstants,
  displayTextKeys,
  labelClass
)
