import { injectBaseConstantMethods } from "./BaseConstants"

const BusinessAccountStatus = {
  ACTIVE: "active",
  PENDING: "pending",
  SUSPENDED: "suspended",
  INVITED: "invited",
  REJECTED: "rejected",
}

const displayTextKeys = {
  [BusinessAccountStatus.ACTIVE]: "Active",
  [BusinessAccountStatus.SUSPENDED]: "Suspended",
  [BusinessAccountStatus.PENDING]: "Pending",
  [BusinessAccountStatus.INVITED]: "Invited",
  [BusinessAccountStatus.REJECTED]: "Rejected",
}

const labelClass = {
  [BusinessAccountStatus.ACTIVE]: "active",
  [BusinessAccountStatus.SUSPENDED]: "suspended",
  [BusinessAccountStatus.PENDING]: "pending",
  [BusinessAccountStatus.INVITED]: "invited",
  [BusinessAccountStatus.REJECTED]: "rejected",
}

export default injectBaseConstantMethods(
  BusinessAccountStatus,
  displayTextKeys,
  labelClass
)
