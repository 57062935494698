import { injectBaseConstantMethods } from "./BaseConstants"

const DepInvenReqStatus = {
  PENDING: "Pending",
  PARTIAL_DELIVERED: "PartialDelivered",
  ALL_DELIVERED: "AllDelivered",
  SUBSITUTE: "Substitutes",
}

const displayTextKeys = {
  [DepInvenReqStatus.PENDING]: "Pending",
  [DepInvenReqStatus.PARTIAL_DELIVERED]: "Partial Delivered",
  [DepInvenReqStatus.ALL_DELIVERED]: "All Delivered",
  [DepInvenReqStatus.SUBSITUTE]: "Substitutes",
}

const labelClass = {
  [DepInvenReqStatus.PENDING]: "",
  [DepInvenReqStatus.PARTIAL_DELIVERED]: "",
  [DepInvenReqStatus.ALL_DELIVERED]: "",
  [DepInvenReqStatus.SUBSITUTE]: "",
}

export default injectBaseConstantMethods(
  DepInvenReqStatus,
  displayTextKeys,
  labelClass
)
