import React, { useState } from "react"
import { Button, ButtonGroup, Input } from "reactstrap"

const Counter = ({
  btnContainerStyles = {},
  btnContainerClasses = "",
  handleIncrement = () => {},
  handleDecrement = () => {},
  value = 0,
}) => {
  const [rSelected, setRSelected] = useState(null)

  return (
    <>
      <ButtonGroup
        style={{ ...btnContainerStyles }}
        className={btnContainerClasses}
      >
        <Button
          active
          color="primary"
          outline
          onClick={handleIncrement}
          className="font-size-15 plus-minus-btn"
        >
          +
        </Button>
        <Input className="counter-text-input text-center" value={value} />

        <Button
          active
          color="primary"
          outline
          onClick={handleDecrement}
          className="font-size-15 plus-minus-btn"
        >
          -
        </Button>
      </ButtonGroup>
    </>
  )
}

export default Counter
