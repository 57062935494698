import { injectBaseConstantMethods } from "./BaseConstants"

const RequestStatus = {
    OPEN: "Open",
    IN_PROGRESS: "InProgress",
    COMPLETED: "Completed",
    PENDING: "Pending",
}

const displayTextKeys = {
    [RequestStatus.OPEN]: "Open",
    [RequestStatus.IN_PROGRESS]: "In Progress",
    [RequestStatus.COMPLETED]: "Completed",
    [RequestStatus.PENDING]: "Pending",
}

const labelClass = {
    [RequestStatus.OPEN]: "bg-red",
    [RequestStatus.IN_PROGRESS]: "bg-orange",
    [RequestStatus.COMPLETED]: "bg-green",
    [RequestStatus.PENDING]: "bg-orange",
}

export default injectBaseConstantMethods(
    RequestStatus,
    displayTextKeys,
    labelClass
)
