export const APP_ROUTES = {
    LOGIN: "/login",
    FORGET_PASSWORD: "/forgot-password",
    FORGET_VERIFY_CODE: "/forget-verify-code",
    RESET_PASSWORD: "/reset-password",
    DASHBOARD: "/dashboard",
    USER_PROFILE: "/profile",
    SETTINGS: "/settings",
    DATA_TABLE: "/data-table",
    INVENTORY: "/inventory-management",
    INVENTORY_DEPARTMENT: "/inventory-management/station",
    INVENTORY_WAREHOUSE: "/inventory-management/warehouse",
    INVENTORY_ITEMS: "/inventory-management/items",
    INVENTORY_REQUEST: "/inventory-request",
    INVENTORY_REQUEST_DEPARTMENT: "/inventory-request/station",
    INVENTORY_REQUEST_PULLBACK: "/inventory-request/transfer-inventory",
    INVENTORY_REQUEST_SCRAP: "/inventory-request/scrap",
    SCRAP_RETURN: "/scrap-return",
    REQUEST_MANAGEMENT: "/request-management",
    REQUEST_MANAGEMENT_REQUESTS: "/request-management/requests",
    REQUEST_MANAGEMENT_REQUESTS_ITEMS: "/requests/:requestId",
    REQUEST_MANAGEMENT_REQUESTS_ITEMS_RECEIVING: "/receiving/:requestItemId",
    REQUEST_MANAGEMENT_POS: "/request-management/poss",
    REQUEST_MANAGEMENT_POS_ITEMS: "/pos/:poId",
}

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj)
        objectKeys.forEach(item => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), match => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1)
                return obj[formattedMatchedValue]
            })
        })
        return route
    }
    return route
}
