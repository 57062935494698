const SelectboxStyles = {
    DepartmentSelection: {
        container: provided => ({
            ...provided,
            width: "250px",
            border: "1px solid #CED4DA",
            borderRadius: "4px",
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: "pointer",
            minHeight: "25px",
            borderColor: "#9d9d9d",
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                borderColor: "#9d9d9d",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#0B4D89" : "white",
            color: state.isSelected ? "white" : "black",
            cursor: "pointer",
        }),
    },
}

export default SelectboxStyles
