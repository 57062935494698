import React, { useEffect, useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import {
    NavLink as RouterNavLink,
    Route,
    Switch,
    Redirect,
    useHistory,
} from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import { getRoute } from "../../helpers/routeHelpers"

const RoutingTabs = ({
    config = null,
    isTabs = true,
    isPills = false,
    isFill = false,
    parentRoute = "",
    containerClass = "",
    navClass = "",
}) => {
    let history = useHistory()
    let params = useParams()
    const [activeTab, setActiveTab] = useState({})

    let currentPath = history?.location?.pathname

    useEffect(() => {
        if (currentPath == getRoute(parentRoute, { ...params })) {
            setActiveTab(config.tabsConfig.find(item => item.defaultActive))
        } else {
            let forActive = config.tabsConfig.find(item => {
                return getRoute(item.path, { ...params }) == currentPath
            })
            setActiveTab(forActive)
        }
    }, [currentPath, config])

    const handleSetActiveTab = id => setActiveTab(id)

    const getRedirect = () => {
        if (!activeTab?.id) return null

        return (
            <Redirect
                exact
                from={getRoute(parentRoute, config?.param)}
                to={getRoute(activeTab?.path, config?.param)}
            />
        )
    }

    return (
        <div className={containerClass}>
            <Nav
                tabs={isTabs}
                pills={isPills}
                fill={isFill}
                className={navClass}
            >
                {config.tabsConfig.map(item => (
                    <NavItem key={item.id}>
                        <NavLink
                            exact
                            tag={RouterNavLink}
                            to={getRoute(item.path, config?.param)}
                            onClick={() => {
                                handleSetActiveTab(item)
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab?.id}>
                <Switch exact>
                    {config.tabsConfig.map((item, ind) => (
                        <Route path={item.path} key={ind}>
                            <TabPane key={ind} tabId={item.id}>
                                {item.component}
                            </TabPane>
                        </Route>
                    ))}
                    {getRedirect()}
                </Switch>
            </TabContent>
        </div>
    )
}

export default RoutingTabs
