import moment from "moment"

export const downloadCSVData = csvText => {
    let hiddenElement = document.createElement("a")
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvText)
    hiddenElement.target = "_blank"
    hiddenElement.download = `template_${new Date().getTime()}.csv`
    hiddenElement.click()
}

export const downloadXlsData = async response => {
    const blob = await response.blob()

    const url = window.URL.createObjectURL(blob)
    let a = document.createElement("a")
    a.href = url
    a.download = `template_${new Date().getTime()}.xlsx`
    document.body.appendChild(a)
    a.click()
    a.remove()
}

export const downloadPdf = async response => {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    let a = document.createElement("a")
    a.href = url
    a.download = `${moment().format("MMMM-Do-YYYY")}.pdf`
    document.body.appendChild(a)
    a.click()
    a.remove()
}

export const downloadFile = file => {
    const link = document.createElement("a")
    link.href = file.path
    link.download = moment(file.createdAt).format("MMMM DD,YYYY")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const convertToCSV = data => {
    const csvRows = []

    // Get headers without 'id'
    const headers = Object.keys(data[0]).filter(header => header !== "id")
    csvRows.push(headers.join(","))

    // Process each row
    for (const row of data) {
        const values = headers.map(header => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"')
            return `"${escaped}"`
        })
        csvRows.push(values.join(","))
    }

    return csvRows.join("\n")
}

export const downloadCSV = (data, filename) => {
    const csvContent = convertToCSV(data)
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
