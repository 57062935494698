import { injectBaseConstantMethods } from "./BaseConstants"

const UserTypes = {
    USER: "USER",
    WAREHOUSE: "WAREHOUSE_MANAGER",
    DEPARTMENT: "DEPARTMENT_MANAGER",
    ADMIN: "ADMIN",
    FINANCE: "FINANCE_MANAGER",
    EXECUTIVE: "EXECUTIVE",
    SUPER_ADMIN: "SUPER_ADMIN",
}

const displayTextKeys = {
    [UserTypes.ADMIN]: "Admin",
    [UserTypes.DEPARTMENT]: "Station Manager",
    [UserTypes.FINANCE]: "Finance Manager",
    [UserTypes.EXECUTIVE]: "Executive",
    [UserTypes.WAREHOUSE]: "Warehouse Manager",
    [UserTypes.USER]: "User",
    [UserTypes.SUPER_ADMIN]: "Super Admin",
}

const labelClass = {}

export default injectBaseConstantMethods(UserTypes, displayTextKeys, labelClass)
