import { injectBaseConstantMethods } from "./BaseConstants"

const BoxTypes = {
  PREBUILT: "prebuilt",
  CUSTOM: "custom",
}

const displayTextKeys = {
  [BoxTypes.PREBUILT]: "Prebuilt",
  [BoxTypes.CUSTOM]: "Custom",
}

const labelClass = {}

export default injectBaseConstantMethods(BoxTypes, displayTextKeys, labelClass)
