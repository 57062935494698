import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { encryptTransform } from "redux-persist-transform-encrypt"

import rootReducer from "./reducers/index"
import rootSaga from "./sagas"
import Config from "../Config"
import { SIGN_OUT } from "../store/actions"

const encryptor = encryptTransform({
    secretKey: Config.PERSIST_SECRET_KEY,
    onError: error => {},
})

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userAuth", "departmentConfig"],
    stateReconciler: autoMergeLevel2,
    transforms: [encryptor],
}

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleWares = [thunk, sagaMiddleware]

const enhancer = composeEnhancers(applyMiddleware(...middleWares))

const store = createStore(persistedReducer, enhancer)

const listener = e => {
    if (
        store.getState().userAuth.isAuthenticated &&
        (!localStorage.hasOwnProperty("isAuthenticated") ||
            !localStorage.isAuthenticated)
    ) {
        store.dispatch({
            type: SIGN_OUT,
        })
        localStorage.clear()
    }
}

window.addEventListener("storage", listener)

sagaMiddleware.run(rootSaga)
let persistor = persistStore(store)

export { store, persistor }
