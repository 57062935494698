import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { AutoComplete } from "components/AutoComplete"
import { SET_SELECTED_DEPARTMENT, ME_UPDATE } from "store/actions"
import SelectboxStyles from "./SelectboxStyles"

const DepartmentSelection = () => {
    const store = useSelector(state => state)
    const dispatch = useDispatch()

    const handleSelectOptionDepartment = option => {
        dispatch({ type: SET_SELECTED_DEPARTMENT, payload: option })
    }

    return (
        <div className="">
            <AutoComplete
                isSearchable
                value={store.departmentConfig.currentSelectedDepartment}
                placeholder="Change Stage"
                customStyles={SelectboxStyles.DepartmentSelection}
                onChange={val => handleSelectOptionDepartment(val)}
                // classNamePrefix="status-header-search"
                options={store?.userAuth?.user?.departmentAssignments?.map(
                    item => ({
                        label: item?.department?.departmentName,
                        value: item?.department?.id,
                        ...item,
                    })
                )}
            />
        </div>
    )
}

export default DepartmentSelection
