import React from "react"
import { Card } from "reactstrap"
import { Link } from "react-router-dom"
import dummyImage from "../../assets/images/icons/inventory-icon.png"

const IconCardLink = ({
  cardClasses = "",
  image = dummyImage,
  isImage = true,
  url = "",
  imageCalsses = "",
  text = "Text here...",
}) => {
  return (
    <>
      <Link to={url || "#"}>
        <Card width="100" className={`${cardClasses} link-card`}>
          <div className="image-container">
            {isImage ? (
              <img src={image} className={`${imageCalsses} __image`} />
            ) : null}
            {text ? <p className="mt-2 text-center __text">{text}</p> : null}
          </div>
        </Card>
      </Link>
    </>
  )
}

export default IconCardLink
