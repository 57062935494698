export default {
    DASHBOARD: "Dashboard",
    LOGIN: "Login",
    FORGET_PASSWORD: "Forgot Password",
    FORGET_VERIFY_CODE: "Forget Verification Code",
    RESET_PASSWORD: "Reset Password",
    SETTINGS: "Settings",
    USER_PROFILE: "User Profile",
    DATA_TABLE: "Data Table",
    SETTINGS: "Settings",
    INVENTORY: "Inventory",
    INVENTORY_MANAGEMENT: "Inventory Management",
    INVENTORY_REQUEST: "Inventory Request",
    SCRAP_RETURN: "Scrap/Return",
    REQUEST_MANAGEMENT: "Order Management",
}
