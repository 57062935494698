import { TableLimitlistingOptions } from "constants"
import React from "react"

const PageLimit = ({
    pageSize,
    changePageSizeHandler,
    typoClasses = "",
    selectClasses = "",
    mainClass = "",
}) => {
    const selectChangeHandler = event => {
        let pageLimit = Number(event.target.value)

        changePageSizeHandler(pageLimit)
    }
    return (
        <>
            <div className={`my-3 ${mainClass}`}>
                <span className={`ms-0 ms-md-2 me-2 ${typoClasses}`}>Show</span>
                <select
                    className={`${selectClasses}`}
                    value={pageSize}
                    onChange={selectChangeHandler}
                >
                    {TableLimitlistingOptions.map(item => (
                        <option key={item} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
                <span className={`mx-2 ${typoClasses}`}>Entries</span>
            </div>
        </>
    )
}

export default PageLimit
