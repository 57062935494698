import React from "react"
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap"
import { useFormik } from "formik"

import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { initialNoteValue, validationNoteSchema } from "./FormConfig"

const COLUMNS = {
    SKU_NUMBER: "SKU No.",
    EXISTING_QTY: "Existing Qty.",
    CSV_QTY: "CSV Qty.",
    ADJUSTMENTS: "Adjustments",
}

const UpdateSelectedItemsListingModal = ({
    title,
    selectedItems,
    onSend,
    loading = false,
}) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialNoteValue,
        validationSchema: validationNoteSchema,
        onSubmit: values => {
            onSend(values)
        },
    })

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Card className="mb-5">
                    <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                        <h3 className="text-center inv-req-popup-heading mb-0">
                            {title}
                        </h3>
                        <DataTable
                            data={selectedItems}
                            tableClasses="finance-im-wh-chemicals"
                            showCheckboxes={false}
                            config={[
                                {
                                    title: COLUMNS.SKU_NUMBER,
                                    render: data => <>{data?.skuNo || "-"}</>,
                                },
                                {
                                    title: COLUMNS.EXISTING_QTY,
                                    render: data =>
                                        data?.existingItemQuantity || "-",
                                },
                                {
                                    title: COLUMNS.CSV_QTY,
                                    render: data => data?.newQuantity || "-",
                                },
                                {
                                    title: COLUMNS.ADJUSTMENTS,
                                    render: data =>
                                        data?.adjustmentQuantity || "-",
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
                {selectedItems.length > 0 && (
                    <div className="m-4">
                        <Row className="mt-5 px-4">
                            <Col
                                sm="12"
                                xs="12"
                                md="12"
                                lg="12"
                                className="px-0 mb-4"
                            >
                                <FormGroup row className="align-items-center">
                                    <Label
                                        for="exampleEmail"
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        align="right"
                                        className=" pe-md-3 ps-0 pe-0 addnewitem-typo textarea-label"
                                    >
                                        Adjustments Notes
                                    </Label>
                                    <Col
                                        sm={9}
                                        md={10}
                                        lg={10}
                                        className="textarea-ip-con ps-md-0 ps-3 pe-4"
                                    >
                                        <Input
                                            name="note"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleChange}
                                            value={formik.values.note}
                                            id="note"
                                            type="textarea"
                                        />
                                        {formik.touched.note &&
                                        formik.errors.note ? (
                                            <span className="error fs-10">
                                                {formik.errors.note}
                                            </span>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-center mt-3">
                            <Button
                                type="submit"
                                size="lg"
                                title="Update"
                                className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                                isLoading={loading}
                            />
                        </div>
                    </div>
                )}
            </Form>
        </>
    )
}

export default UpdateSelectedItemsListingModal
