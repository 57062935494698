import React, { useState } from "react"
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
} from "reactstrap"
import classnames from "classnames"

import { TableTopHeading } from "components/TableTopHeading"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import WHInventoryManagementWI from "assets/images/warehouse/inventory-management/wh-inventory-management-icon.png"
import { getAppName } from "utils/commonUtils"
import ScrapReturnDepartmentRequestListing from "./InventoryReq-Department-ScrapReturnListing"
import ScrapReturnWarehouseRequestListing from "./InventoryReq-Warehouse-ScrapReturnListing"

const InventoryRequestScrapReturn = ({}) => {
    const [activeTabSecond, setActiveTabSecond] = useState("ic1")

    const toggle = tab => {
        if (activeTabSecond !== tab) {
            setActiveTabSecond(tab)
        }
    }

    let tabsConfig = [
        {
            title: "Warehouse",
            identifier: "ic1",
            content: <ScrapReturnWarehouseRequestListing />,
        },
        {
            title: "Station",
            identifier: "ic2",
            content: <ScrapReturnDepartmentRequestListing />,
        },
    ]
    return (
        <>
            <div className={"warehouse-nav-tabs"}>
                <TableTopHeading
                    title="Scrap / Return Requests"
                    iconImage={InventoryRequestIcon}
                    iconClass={"inventory-req-icon"}
                    className="mb-3"
                />

                <>
                    <Nav tabs className="border-0">
                        {tabsConfig &&
                            tabsConfig.map(el => {
                                return (
                                    <NavItem key={el.identifier}>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    activeTabSecond ===
                                                    el.identifier,
                                            })}
                                            onClick={() => {
                                                toggle(el.identifier)
                                            }}
                                        >
                                            {el.title}
                                        </NavLink>
                                    </NavItem>
                                )
                            })}
                    </Nav>
                    <TabContent activeTab={activeTabSecond}>
                        {tabsConfig &&
                            tabsConfig.map(el => {
                                return (
                                    <TabPane
                                        tabId={el.identifier}
                                        key={el.identifier}
                                    >
                                        <Row>
                                            <Col sm="12">
                                                {activeTabSecond ==
                                                    el.identifier && el.content}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                )
                            })}
                    </TabContent>
                </>
            </div>
        </>
    )
}

export default InventoryRequestScrapReturn
