import * as Yup from "yup"

const initialValues = {
    id: "",
    itemType: "",
    skuNo: "",
    itemName: "",
    description: "",
    majorThreshold: "",
    minorThreshold: "",
    mediumThreshold: "",
    itemsCategoryId: "",
    itemsVendorId: "",
    quantity: 0,
}

const initialNoteValue = {
    note: "",
}

const validationNoteSchema = Yup.object({
    note: Yup.string().required("Adjustments Note is required"),
})

const validationSchema = Yup.object({
    id: Yup.number(),
    itemType: Yup.string()
        .required("Item Type is required")
        .nullable("Item Type is required"),
    skuNo: Yup.string().required("SKU NO. is required"),
    itemName: Yup.string()
        .required("Item Name is required")
        .min(3, "Item Name should be minimum 6 characters long")
        .max(200, "Item Name should be between 6 to 25 characters"),
    description: Yup.string().required("Description is required"),

    minorThreshold: Yup.number()
        .typeError("Please enter a number")
        .required("Minor Threshold is required")
        .min(0),
    mediumThreshold: Yup.number()
        .typeError("Please enter a number")
        .required("Medium Threshold is required")
        .test(
            "is-greater",
            "Minor shortage should be greater than major shortage",
            function (value) {
                return value > this.parent.minorThreshold
            }
        ),

    itemsCategoryId: Yup.string()
        .required("Category is required")
        .nullable("Category is required"),
    itemsVendorId: Yup.string()
        .required("Vendor  is required")
        .nullable("Vendor  is required"),
    quantity: Yup.number("Enter quantity").optional(),
})

export {
    initialValues,
    validationSchema,
    initialNoteValue,
    validationNoteSchema,
}
