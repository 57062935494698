import Config from "Config"
import { useState, useEffect, useRef } from "react"
import direction from "../constants/SortOrder"

export const useLocalPaginatedRequest = ({
    requestFn,
    params = {},
    deps = [],
    searchKey = "Q",
    dataKey = "Data",
    totalKey = "TotalCount",
    id = null,
    successCallback = () => {},
    isLoadMore = false,
}) => {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(Config.LIMIT)
    const [searchText, setSearchText] = useState("")
    const [data, setData] = useState("")
    const [total, setTotal] = useState("")
    const [isFetching, setIsFetching] = useState(false)
    const [pageLimit, setPageLimit] = useState(Config.LIMIT)
    const [wholeResponse, setWholeResponse] = useState("")
    const requestAbortController = useRef(null)

    useEffect(() => {
        requestAbortController.current = new AbortController()

        request()

        return () => {
            if (requestAbortController.current) {
                requestAbortController.current.abort()
            }
        }
    }, [pageSize, page, searchText, pageLimit, ...deps])

    const request = () => {
        {
            !isLoadMore && setData([])
        }
        setIsFetching(true)
        requestFn(
            {
                limit: pageSize,
                page: page,
                direction: direction.DESC,
                column: "createdAt",
                ...(searchText?.trim() && { [searchKey]: searchText?.trim() }),
                ...(Object.keys(params).length && params),
            },
            requestAbortController.current.signal,
            id
        )
            .then(res => {
                setWholeResponse(res)
                setData(prev => [...prev, ...res[dataKey]])
                setTotal(res[totalKey])
                successCallback(res[dataKey])
            })
            .finally(() => {
                setIsFetching(false)
            })
    }

    const handleSearchChange = e => {
        let searchText = e.target.value
        setSearchText(searchText)
        setPage(1)
    }

    const handlePageClick = pageNo => {
        setPage(pageNo)
    }

    const onChangePageSize = number => {
        setPageSize(number)
        setPage(1)
    }

    return {
        page,
        searchText,
        handleSearchChange,
        handlePageClick,
        pageSize,
        onChangePageSize,
        data,
        total,
        request,
        isFetching,
        setData,
        wholeResponse,
    }
}
