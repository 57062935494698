import React, { useRef } from "react"

import modalCloseIcon from "../../../assets/images/inventory-request/modal-close-icon.png"
import manifestUploadImg from "../../../assets/manifest-upload-image.svg"
import manifestPdfImage from "../../../assets/images/icons/manifest-icon.svg"

const ManifestUpload = ({
    image,
    onChange,
    isNewImageSet = false,
    removeImageHandler = () => {},
}) => {
    const profileFileUpload = useRef(null)

    const handleFileUploadClick = () => {
        profileFileUpload.current.click()
    }

    return (
        <div className="m-0 p-0">
            {!isNewImageSet && (
                <>
                    <div
                        role="button"
                        onClick={handleFileUploadClick}
                        style={{ width: "fit-content", height: "fit-content" }}
                    >
                        <div>
                            <img
                                src={manifestUploadImg}
                                style={{ width: "150px", height: "150px" }}
                                className="pb-5"
                            />
                        </div>
                        <input
                            id="manifestUpload"
                            ref={profileFileUpload}
                            type="file"
                            hidden
                            onChange={onChange}
                            accept=".doc,.docx,.pdf,.png,.jpg,.jpeg"
                        />
                    </div>
                </>
            )}

            {isNewImageSet && (
                <div className="uploaded-file-box">
                    <div className="media-remove-btn-container">
                        <button
                            className="custom-btn-close-popup media-remove-btn"
                            onClick={() => removeImageHandler()}
                            type="button"
                        >
                            <img src={modalCloseIcon} />
                        </button>
                    </div>
                    <div>
                        <div>
                            <img
                                src={manifestPdfImage}
                                className="imageHandle"
                                style={{ width: "100px", height: "100px" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ManifestUpload
