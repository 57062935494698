import React from "react"
import { Form, FormGroup, Label, Input } from "reactstrap"

import { Button } from "components/Button"

const AddCategoryForm = ({
  isLoading = false,
  value = "",
  isUpdate = false,
  onChange = () => {},
  onSubmit = () => {},
}) => {
  return (
    <Form inline className="p-4" onSubmit={e => e.preventDefault()}>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0 d-flex flex-column align-items-center">
        {/* <Label for="category-name" className="mr-sm-2">
          Category Name:
        </Label> */}
        <Input
          value={value}
          onChange={onChange}
          className="w-75"
          type="text"
          name="category-name"
          id="category-name"
          placeholder="Type category name"
        />
      </FormGroup>
      <div className="w-100 d-flex justify-content-center mt-3 category-btn-con">
        <Button
          disabled={!value}
          isLoading={isLoading}
          size="lg"
          onClick={onSubmit}
          className="gt-btn-grad-primary  w-75 text-center mt-2 btn-alignment-category"
          title={<div>{isUpdate ? "Update" : "Add"}</div>}
        />
      </div>
    </Form>
  )
}

export default AddCategoryForm
