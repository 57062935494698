import { injectBaseConstantMethods } from "./BaseConstants"

const ReadStatus = {
  UNREAD: "un-read",
  READ: "read",
}

const displayTextKeys = {}

const labelClass = {}

export default injectBaseConstantMethods(
  ReadStatus,
  displayTextKeys,
  labelClass
)
