import React from "react"
import { Button as ReactstrapButton } from "reactstrap"

const Button = props => {
    const {
        onClick = () => {},
        className = "",
        title = "button title",
        isLoading = false,
        type = "button",
        size = "md",
        disabled,
        styles = {},
    } = props
    return (
        <ReactstrapButton
            disabled={isLoading || disabled}
            onClick={onClick}
            className={`btn gt-btn gt-btn-${size} shadow-none ${className}`}
            type={type}
            style={styles}
        >
            {isLoading ? (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                title
            )}
        </ReactstrapButton>
    )
}
export default Button
