import React from "react"
import { Badge, Button } from "reactstrap"

const Refresh = ({ onClick = () => {}, isDisable = false }) => {
    return (
        <Button
            className="refresh-container pointer"
            onClick={onClick}
            color="light"
            disabled={isDisable}
        >
            <>
                <i className="bx bx-sm bx-refresh refresh-icon"></i>
            </>
        </Button>
    )
}

export default Refresh
