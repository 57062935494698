import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { Loader } from "../../components/Loader"
import { Page500 } from "../../components/Utility"
import { getMe } from "../../store/actions"
import { APP_ROUTES } from "helpers/routeHelpers"
import { departmentRoutes, warehouseRoutes } from "routes"
import { UserTypes } from "constants"

const DefaultLayout = props => {
    const { Layout } = props
    const dispatch = useDispatch()
    const userType = useSelector(state => state?.userAuth?.user?.type)
    const { tokenVerified, isLoading } = useSelector(state => state.root)
    const { isAuthenticated } = useSelector(state => state.userAuth)

    const routes =
        userType == UserTypes.DEPARTMENT ? departmentRoutes : warehouseRoutes

    // const routes =
    //   userType == UserTypes.WAREHOUSE ? warehouseRoutes : departmentRoutes

    useEffect(() => {
        if (isAuthenticated) {
            if (!tokenVerified) {
                dispatch(getMe()).catch(() => {})
            }
        }
    }, [dispatch])

    if (!isAuthenticated) {
        return <Redirect to={APP_ROUTES.LOGIN} />
    }

    return tokenVerified ? (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <Layout>
                                <route.component {...props} />
                            </Layout>
                        )}
                    />
                )
            })}
            <Redirect to={APP_ROUTES.DASHBOARD} />
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader classes="vh-100" />
    ) : (
        <Page500 />
    )
}

export default DefaultLayout
