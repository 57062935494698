import React, { useEffect, useState } from "react"

const useTimer = ({
  isOpen = false,
  initialMinute = 1,
  initialSeconds = 0,
  handleTimeOut = () => {},
}) => {
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)
  useEffect(() => {
    if (isOpen) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds == 0) {
          if (minutes == 0) {
            clearInterval(myInterval)
            handleTimeOut()
            setMinutes(initialMinute)
            setSeconds(initialSeconds)
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [isOpen, minutes, seconds])

  return minutes === 0 && seconds === 0
    ? {}
    : {
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
      }
}

export default useTimer
