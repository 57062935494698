import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"

import { Button } from "components/Button"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import InventoryRequestDepartmentListing from "./InventoryReq-DepartmentListing"
import InventoryRequestPullbackListing from "./InventoryReq-PullbackListing"

const InventoryRequestListing = () => {
    const history = useHistory()

    const [isActive, setIsActive] = useState({
        departmentRequest: true,
        pullBackRequest: false,
    })

    const onClickDepartmentRequest = () => {
        setIsActive({
            ...isActive,
            departmentRequest: true,
            pullBackRequest: false,
        })
    }
    const onClickPullBackRequest = () => {
        setIsActive({
            ...isActive,
            departmentRequest: false,
            pullBackRequest: true,
        })
    }

    const headerOuterButtonsData = [
        {
            title: "Station Requests",
            onClick: onClickDepartmentRequest,
            classesButton: "ms-0 ms-md-2",
            identifier: "departmentRequest",
        },
        {
            title: "Transfer Inventory Request",
            onClick: onClickPullBackRequest,
            classesButton: "ms-2",
            identifier: "pullBackRequest",
        },
    ]

    return (
        <React.Fragment>
            <div className="gt-business-accounts px-3 px-md-5">
                <Container fluid>
                    <Row>
                        <Col className="col-12 ">
                            <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3 inv-req-top-con">
                                <BackButtonTableTop
                                    className="mb-0 "
                                    backarrowCustom="ir-top-header"
                                />
                                <TableHeaderButtons
                                    data={headerOuterButtonsData}
                                    classes={" px-4 btn-alignment "}
                                    isActive={isActive}
                                />
                            </div>

                            {isActive.departmentRequest ? (
                                <InventoryRequestDepartmentListing />
                            ) : (
                                <InventoryRequestPullbackListing />
                            )}

                            <div className="text-center ">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                                    title={<div>Back to Main</div>}
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default InventoryRequestListing
