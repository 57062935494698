import React, { useEffect, useState } from "react"
import { Form, Row, Col, Container } from "reactstrap"
import { Link, Redirect, useHistory, useLocation } from "react-router-dom"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers"

import { useFormik } from "formik"
import * as Yup from "yup"
import logo from "../../assets/images/logo-colored.png"
import { useDispatch, useSelector } from "react-redux"
import { withAuthentication } from "hoc"
import { ResetPassword as resetPassword } from "api/api.service"

const PASSWORD_MUST_MATCH = "Passwords must match"

const ResetPassword = props => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  if (location?.state?.data) {
    const { code, token } = location.state?.data
    if (!(code && token)) {
      history.push(APP_ROUTES.LOGIN)
    }
  } else {
    history.push(APP_ROUTES.LOGIN)
  }

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .min(6, "Please enter a password between 6 and 20 characters")
        .max(20, "Please enter a password between 6 and 20 characters")
        .required("Please enter your password"),
      confirmPassword: Yup.string()
        .trim()
        .oneOf([Yup.ref("password"), null], PASSWORD_MUST_MATCH)
        .min(6, "Please enter a password between 6 and 20 characters")
        .max(20, "Please enter a password between 6 and 20 characters")
        .required("Please enter your password"),
    }),
    onSubmit: ({ password }) => {
      handleSubmit(password?.trim())
    },
  })

  const handleSubmit = async password => {
    const { code, token } = location.state.data
    const data = {
      Code: code,
      TokenUUID: token,
      Password: password,
    }
    try {
      setLoading(true)
      const res = await resetPassword(data)
      history.push(getRoute(APP_ROUTES.LOGIN))
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <div className="d-flex justify-content-center">
          <div className="text-center mb-5 text-muted">
            <Link to={APP_ROUTES.LOGIN} className="d-block auth-logo">
              <img src={logo} className="mx-auto" />
            </Link>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div className="p-2">
              <div className="text-center">
                <div className="avatar-md mx-auto">
                  <div className="avatar-title rounded-circle bg-light">
                    <i className="bx bxs-lock-alt fs-32 mb-0 clr-purple"></i>
                  </div>
                </div>
                <div className="p-2 mt-4">
                  <h4 className="fs-19 clr-gray-dark">Enter New Password</h4>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      formik.handleSubmit()
                    }}
                  >
                    <div className="mb-3 mt-4">
                      <TextInput
                        withIcon={true}
                        className="d-flex flex-column remove-validation-icons"
                        name="password"
                        placeholder="New password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.password && !!formik.errors.password
                        }
                        valid={
                          formik.touched.password && !formik.errors.password
                        }
                      />
                      {formik.touched.password && !!formik.errors.password && (
                        <div className="form-input-error">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 mt-4">
                      <TextInput
                        withIcon={true}
                        className="d-flex flex-column remove-validation-icons"
                        name="confirmPassword"
                        placeholder="Confirm password"
                        type="password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.confirmPassword &&
                          !!formik.errors.confirmPassword
                        }
                        valid={
                          formik.touched.confirmPassword &&
                          !formik.errors.confirmPassword
                        }
                      />
                      {formik.touched.confirmPassword &&
                        !!formik.errors.confirmPassword && (
                          <div className="form-input-error">
                            {formik.errors.confirmPassword}
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <Button
                        title="Confirm"
                        size="sm"
                        className="gt-btn-grad-primary"
                        type="submit"
                        isLoading={loading}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withAuthentication(ResetPassword)
