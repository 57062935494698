import * as Yup from "yup"

export const initialValues = {
  CurrentPassword: "",
  NewPassword: "",
  confirmNewPassword: "",
}

export const validationSchema = Yup.object().shape({
  CurrentPassword: Yup.string().required("Enter Old Password"),
  NewPassword: Yup.string().required("Enter New Password"),
  // .min(8, "Password must be 8 characters long")
  // .matches(/[0-9]/, "Password requires a number")
  // .matches(/[a-z]/, "Password requires a lowercase letter")
  // .matches(/[A-Z]/, "Password requires an uppercase letter")
  // .matches(/[^\w]/, "Password requires a symbol"),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("NewPassword"), null],
    'Must match "password" field value'
  ),
})
