import React, { useState } from "react"
import PropTypes from "prop-types"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import avatarPalceholerImg from "assets/images/users/avatar-paceholder.png"
import { SIGN_OUT } from "store/actions"
import { getMediaPath } from "utils/mediaUtils"
import { UserTypes } from "../../../constants"

const ProfileMenu = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false)
    // Added modal support to be use in future for casita
    const [showLogoutModal, setShowLogoutModal] = useState(false)
    const user = useSelector(state => state.userAuth?.user)

    const handleLogoutModal = () => {
        setShowLogoutModal(true)
    }

    const handleLogout = () => {
        localStorage.clear()
        dispatch({ type: SIGN_OUT })
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item d-flex justify-content-center align-items-center"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={getMediaPath(user?.Media) || avatarPalceholerImg}
                        alt=""
                    />

                    <span className="d-xl-inline-block ms-2 me-1">
                        {user?.firstName + " " + user?.lastName || "User"}
                        <hr className="m-0 p-0" />
                        <span style={{ fontWeight: "500" }}>
                            {UserTypes.getDisplayTextKey(user.type)}
                        </span>
                    </span>

                    <i className="bx bx-chevron-down d-xl-inline-block"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                    {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
                    {/* <div className="dropdown-divider" /> */}

                    <div
                        className="dropdown-item pointer"
                        onClick={handleLogout}
                    >
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>Logout</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
}

const mapStatetoProps = state => {
    const profile = { success: true, error: false }
    const { error, success } = profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
