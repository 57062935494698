import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import customStyles from "./HeaderStatusStyling"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import { InventoryReqItemsStatus, RequestStatus } from "../../../constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { getSelectBoxOptionsFromConstants } from "utils/commonUtils"
import { GetInventoryItemById } from "api/api.service"
import useGetPdfReport from "hooks/useGetPdfReport"

const COLUMNS = {
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    REQUESTED_QUANTITY: "Requested Quantity",
    STATUS: "Status",
    STATUS_UPDATE: "Status Update",
    QUANTITY_SUPPLIED: "Supplied Quantity",
    SUBTITUTED_ITEM_NAME: "Subsitute Name",
    REQUESTED_DATE: "Requested Date",
    AVAIALBLE_QUANTITY: "Available Quantity",
    QUANTITY_DELIVERED: "Deliver Quantity",
}

const PDF_TYPE = "Department"

const DepartmentRequestItems = ({
    data = {},
    isSubmitting = false,
    isPullbackRequests = true,
    onSubmit = () => {},
    modalCloseHandler = () => {},
}) => {
    const [itemsData, setItemsData] = useState([])
    let statusOptions = getSelectBoxOptionsFromConstants(
        InventoryReqItemsStatus
    )

    useEffect(() => {
        let clone = [...data.items]
        clone = clone?.map(item => ({
            ...item,
            inputQty: item.warehouseQuantity > 1 && 0,
            subsituteItemsOptions: null,
            isShowItemsSelectBox: false,
            selectedSubsituteItem: null,
            selectedStatusFromOptions: null,
            availableStatusOptions: handleStatusOptions(item),
            remainingQuantity: (
                item?.quantity - item?.quantitySupplied
            )?.toFixed(2),
            hasInputError: false,
            isDisableInputQty: true,
            isDisableItemsOptionsSelectbox: true,
            copyOfAvailableQuantity: item.warehouseQuantity,
            fetchingSubsituteItems: false,
            itemsOptionsSelectPlaceholder: "Select Item",
        }))
        setItemsData([...clone])
    }, [])

    const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

    const handleProvidedQuantityInput = (evt, targetObj, index) => {
        if (evt.target.value == "-") return
        let indexOfTargetObj = index
        let inputQty = Math.abs(evt.target.value)
        let stateCopy = [...itemsData]
        stateCopy[indexOfTargetObj] = {
            ...targetObj,
        }

        if (
            +inputQty > targetObj.remainingQuantity ||
            +inputQty > targetObj.warehouseQuantity ||
            +inputQty == 0
        ) {
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                hasInputError: true,
            }
            return
        } else {
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                inputQty: Number(inputQty),
            }
        }

        setItemsData(stateCopy)
    }

    const handleChangeStatus = (selectedOption, targetObj, index) => {
        let indexOfTargetObj = index
        let stateCopy = [...itemsData]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        switch (selectedOption.value) {
            case "Pending":
                refObj.selectedStatusFromOptions = { ...selectedOption }
                refObj.status = selectedOption.value
                refObj.inputQty = ""
                refObj.subsituteItemsOptions = null
                refObj.selectedSubsituteItem = null
                refObj.hasInputError = false
                refObj.isDisableInputQty = true
                refObj.isDisableItemsOptionsSelectbox = true
                refObj.isShowItemsSelectBox = false
                refObj.warehouseQuantity = refObj.copyOfAvailableQuantity

                refObj.availableStatusOptions = handleStatusOptions(refObj)

                setItemsData(stateCopy)

                return
            case "AllDelivered":
                if (refObj.status != InventoryReqItemsStatus.Substitutes) {
                    refObj.selectedStatusFromOptions = { ...selectedOption }
                    refObj.status = selectedOption.value
                    refObj.inputQty = refObj.remainingQuantity
                    refObj.hasInputError = false
                    refObj.isDisableInputQty = true
                    refObj.isDisableItemsOptionsSelectbox = true

                    setItemsData(stateCopy)
                }
                return

            case "PartialDelivered":
                if (refObj.status != InventoryReqItemsStatus.Substitutes) {
                    refObj.selectedStatusFromOptions = { ...selectedOption }
                    refObj.status = selectedOption.value
                    refObj.inputQty = ""
                    refObj.hasInputError = false
                    refObj.isDisableInputQty = false
                    refObj.isDisableItemsOptionsSelectbox = true

                    setItemsData(stateCopy)
                }

                return
            case "Substitutes":
                refObj.fetchingSubsituteItems = true
                getSubsitutedItems(refObj.itemsId)
                    .then(res => {
                        let subsituteItemsOptions = res?.data
                            ?.map(item => ({
                                ...item,
                                label: item.itemName,
                                value: item.id,
                                sku: item.skuNo,
                            }))
                            ?.filter(
                                option =>
                                    option?.quantity &&
                                    option?.quantity > 0 &&
                                    option
                            )

                        refObj.isShowItemsSelectBox = true
                        refObj.inputQty = ""
                        refObj.subsituteItemsOptions =
                            subsituteItemsOptions?.length
                                ? [...subsituteItemsOptions]
                                : null
                        refObj.selectedSubsituteItem = null
                        refObj.selectedStatusFromOptions = { ...selectedOption }
                        refObj.status = selectedOption.value
                        refObj.hasInputError = false
                        refObj.isDisableInputQty = true
                        refObj.isDisableItemsOptionsSelectbox = !Boolean(
                            subsituteItemsOptions?.length
                        )
                        refObj.itemsOptionsSelectPlaceholder =
                            subsituteItemsOptions?.length
                                ? "Select Item"
                                : "Not Available"
                        refObj.availableStatusOptions =
                            refObj.availableStatusOptions.filter(option => {
                                if (
                                    option.value ==
                                        InventoryReqItemsStatus.Partial_Delivered ||
                                    option.value ==
                                        InventoryReqItemsStatus.All_Delivered
                                ) {
                                    return false
                                } else {
                                    return true
                                }
                            })

                        setItemsData(stateCopy)
                    })
                    .catch(err => {
                        // toast.error(err.message)
                    })
                    .finally(() => {
                        refObj.fetchingSubsituteItems = false
                    })
                return
        }
    }

    const getSubsitutedItems = async (id = "") => {
        if (!id) return

        try {
            let res = await GetInventoryItemById(id)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const handleItemsSelectboxChange = (option, targetObj, index) => {
        let indexOfTargetObj = index
        let stateCopy = [...itemsData]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        refObj.selectedSubsituteItem = option
        refObj.warehouseQuantity = option.quantity
        refObj.isDisableInputQty = false
        refObj.substituteId = option.value
        setItemsData(stateCopy)
    }

    const handleSubmit = () => {
        let toastId

        if (data.status == RequestStatus.COMPLETED) {
            modalCloseHandler()
            return
        }

        let rawData = [...itemsData]?.filter(
            obj => obj.status != "Pending" && obj.quantitySupplied == 0
        )

        if (!rawData.length) {
            toast.dismiss(toastId)
            toastId = toast.error(
                "Kindly fulfill the request with appropriate status !"
            )
            return
        }

        if (rawData?.filter(item => item.inputQty == 0).length) {
            toast.error("Kindly add the requested quantity !")
            return
        }

        let finalData = {
            id: data.id,
            departmentId: data.departmentId,
            items: [
                ...rawData?.map((obj, idx) => {
                    return {
                        id: obj.id,
                        departmentRequestId: obj.departmentRequestId,
                        status: obj.status,
                        quantity: Number(obj.quantity),
                        quantitySupplied: Math.abs(obj.inputQty),

                        itemsId: obj.itemsId,
                        ...(obj?.selectedSubsituteItem?.value && {
                            substituteId: obj.substituteId,
                        }),
                    }
                }),
            ],
        }

        onSubmit(finalData)
    }

    const handleStatusOptions = data => {
        let remainingQuantity = Math.abs(
            data?.quantity - data?.quantitySupplied
        )

        return statusOptions?.map(item => {
            return {
                ...item,

                ...(item.value == InventoryReqItemsStatus.All_Delivered &&
                    data.warehouseQuantity < remainingQuantity && {
                        isDisabled: true,
                    }),

                ...(item.value == InventoryReqItemsStatus.Partial_Delivered &&
                    remainingQuantity == 1 && {
                        isDisabled: true,
                    }),

                ...(item.value == InventoryReqItemsStatus.Partial_Delivered &&
                    data.warehouseQuantity == 0 && {
                        isDisabled: true,
                    }),
            }
        })
    }
    return (
        <>
            {data?.items?.length && (
                <div>
                    <h3 className="text-center inv-req-popup-heading">
                        Request Id : {data?.id || "-"}
                    </h3>
                    <DataTable
                        data={itemsData}
                        className="view-detail-inv-req-table department-req-items-table height-scroll-table"
                        tableClasses="inv-req-dpt-detail-table"
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName
                                },
                            },
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return data?.skuNo
                                },
                            },
                            {
                                title: COLUMNS.SUBTITUTED_ITEM_NAME,
                                render: data => {
                                    return data?.substitute?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_QUANTITY,
                                render: data => {
                                    return data?.quantity
                                },
                            },
                            {
                                title: COLUMNS.AVAIALBLE_QUANTITY,
                                render: data => {
                                    return data?.warehouseQuantity
                                },
                            },
                            {
                                title: COLUMNS.QUANTITY_SUPPLIED,
                                render: data => {
                                    return data?.quantitySupplied
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_DATE,
                                render: data => {
                                    return (
                                        <div className="request-date-width">
                                            {convertDateTime({
                                                date: data.createdAt,
                                                customFormat:
                                                    dateTimeFormat.casitaCutomDateFormat,
                                                dateOnly: true,
                                            })}
                                        </div>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: (data, idx) => {
                                    return (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div
                                                className={`inv-req-popup-status inv-req-status-width me-3 ${InventoryReqItemsStatus.getLabelClass(
                                                    data.status
                                                )}`}
                                            >
                                                {InventoryReqItemsStatus.getDisplayTextKey(
                                                    data.status
                                                )}
                                            </div>

                                            {data.quantitySupplied == 0 && (
                                                <div>
                                                    <AutoComplete
                                                        isSearchable={false}
                                                        placeholder="Select Status"
                                                        classNamePrefix="view-detail-select"
                                                        customStyles={
                                                            customStyles.ModalVD
                                                        }
                                                        options={
                                                            data.availableStatusOptions
                                                        }
                                                        value={
                                                            data.selectedStatusFromOptions
                                                        }
                                                        onChange={val =>
                                                            handleChangeStatus(
                                                                val,
                                                                data,
                                                                idx
                                                            )
                                                        }
                                                        maxMenuHeight={100}
                                                        filterOption={option =>
                                                            option.value !==
                                                            InventoryReqItemsStatus.Cancelled
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {data.isShowItemsSelectBox && (
                                                <div className="ms-3">
                                                    <AutoComplete
                                                        isDisabled={
                                                            data.isDisableItemsOptionsSelectbox
                                                        }
                                                        isLoading={
                                                            data.fetchingSubsituteItems
                                                        }
                                                        isSearchable={true}
                                                        placeholder={
                                                            data.itemsOptionsSelectPlaceholder
                                                        }
                                                        classNamePrefix="view-detail-select"
                                                        customStyles={
                                                            customStyles.ModalVD
                                                        }
                                                        options={data?.subsituteItemsOptions?.filter(
                                                            item =>
                                                                item.sku !=
                                                                data.skuNo
                                                        )}
                                                        value={
                                                            data.selectedSubsituteItem
                                                        }
                                                        onChange={val =>
                                                            handleItemsSelectboxChange(
                                                                val,
                                                                data,
                                                                idx
                                                            )
                                                        }
                                                        maxMenuHeight={100}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                },
                            },

                            {
                                isHide: false,
                                title: COLUMNS.QUANTITY_DELIVERED,
                                render: (data, idx) => {
                                    if (data.quantitySupplied > 0) {
                                        return "-"
                                    }
                                    return (
                                        <TextInput
                                            disabled={data.isDisableInputQty}
                                            value={data.inputQty}
                                            invalid={data.hasInputError}
                                            onChange={e =>
                                                handleProvidedQuantityInput(
                                                    e,
                                                    data,
                                                    idx
                                                )
                                            }
                                            className="view-detail-inv-req-p-ip-con"
                                            placeholder=""
                                            labelClass="d-none"
                                            inputClass="view-detail-inv-req-p-ip"
                                            min={0.1}
                                            step="0.0000001"
                                            max={`${data?.remainingQuantity}`}
                                            type="number"
                                        />
                                    )
                                },
                            },
                        ]}
                    />
                    <div className="text-center my-4">
                        <Button
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 btn-alignment"
                            title={<div>Done</div>}
                            isLoading={isSubmitting}
                            onClick={handleSubmit}
                        />
                        <Button
                            isLoading={fetchingReport}
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 px-5 btn-alignment"
                            title={<div>Print Orders</div>}
                            onClick={() =>
                                handleGetPdfReport(data?.id, PDF_TYPE)
                            }
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default DepartmentRequestItems
