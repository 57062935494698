import { SET_SELECTED_DEPARTMENT } from "store/actions"

const initialState = {
    currentSelectedDepartment: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DEPARTMENT:
            return {
                ...state,
                currentSelectedDepartment: action.payload,
            }
        default:
            return state
    }
}
