import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import customStyles from "./HeaderStatusStyling"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import { InventoryReqItemsStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { getSelectBoxOptionsFromConstants } from "utils/commonUtils"
import { GetInventoryItemById } from "api/api.service"
import useGetPdfReport from "hooks/useGetPdfReport"

const COLUMNS = {
  ITEM_NAME: "Item Name",
  SKU_NO: "SKU No.",
  REQUESTED_QUANTITY: "Requested Quantity",
  STATUS: "Status",
  STATUS_UPDATE: "Status Update",
  QUANTITY_SUPPLIED: "Supplied Quantity",
  REQUESTED_DATE: "Requested Date",
  // AVAIALBLE_QUANTITY: "Available Quantity",
  QUANTITY_DELIVERED: "Deliver Quantity",
}
const PDF_TYPE = "Pullback"

const PullbackRequestItems = ({ data = {}, modalCloseHandler = () => {} }) => {
  const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

  const [itemsData, setItemsData] = useState([])

  useEffect(() => {
    if (data?.items?.length) {
      setItemsData([...data?.items])
    }
  }, [data])

  return (
    <>
      {itemsData?.length && (
        <div>
          <h3 className="text-center inv-req-popup-heading">
            Request Id : {data?.id || "-"}
          </h3>
          <DataTable
            data={itemsData}
            className="view-detail-inv-req-table department-req-items-table"
            tableClasses="inv-req-scrapreturn-table"
            config={[
              {
                title: COLUMNS.ITEM_NAME,
                render: data => {
                  return data?.itemName
                },
              },
              {
                title: COLUMNS.SKU_NO,
                render: data => {
                  return data?.skuNo
                },
              },
              {
                title: COLUMNS.REQUESTED_QUANTITY,
                render: data => {
                  return data?.quantity
                },
              },
              // {
              //   title: COLUMNS.AVAIALBLE_QUANTITY,
              //   render: data => {
              //     return data?.departmentQuantity
              //   },
              // },
              {
                title: COLUMNS.QUANTITY_SUPPLIED,
                render: data => {
                  return data?.quantitySupplied
                },
              },
              {
                title: COLUMNS.REQUESTED_DATE,
                render: data => {
                  return convertDateTime({
                    date: data.createdAt,
                    customFormat: dateTimeFormat.casitaCutomDateFormat,
                    dateOnly: true,
                  })
                },
              },
              {
                title: COLUMNS.STATUS,
                render: (data, idx) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-evenly"
                      key={idx}
                    >
                      <div
                        className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                          data.status
                        )}`}
                      >
                        {InventoryReqItemsStatus.getDisplayTextKey(data.status)}
                      </div>
                    </div>
                  )
                },
              },
            ]}
          />
          <div className="text-center my-4">
            <Button
              size="lg"
              className="gt-btn-grad-primary table-bottom-btn mx-2"
              title={<div>Done</div>}
              onClick={modalCloseHandler}
            />
            <Button
              isLoading={fetchingReport}
              size="lg"
              title={<div>Print Orders</div>}
              className="gt-btn-grad-primary table-bottom-btn mx-2 px-5 btn-alignment"
              onClick={() => handleGetPdfReport(data?.id, PDF_TYPE)}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PullbackRequestItems
