import React from "react"
import { Button } from "components/Button"

const HeaderButtons = ({
    data = [],
    classes = "",
    isActive = undefined,
    size = "lg",
    type = "button",
}) => {
    return (
        <>
            {data?.length &&
                data.map((item, index) => {
                    return (
                        <Button
                            disabled={item.isDisable}
                            styles={{ height: "auto" }}
                            key={index}
                            size={size}
                            title={
                                <>
                                    {/* <img src={AddIcon} className="img-fluid mr1" /> */}
                                    {item?.title}
                                </>
                            }
                            className={`${classes} ${item.classesButton} ${
                                isActive !== undefined &&
                                (isActive[item.identifier] === true
                                    ? "gt-btn-grad-primary"
                                    : "gt-btn-secondary")
                            } `}
                            style={{ cursor: "pointer" }}
                            onClick={evt => item.onClick(true)}
                        />
                    )
                })}
        </>
    )
}

export default HeaderButtons
