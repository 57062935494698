import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import customStyles from "./HeaderStatusStyling"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import { InventoryReqItemsStatus, RequestStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { getSelectBoxOptionsFromConstants } from "utils/commonUtils"
import { GetInventoryItemById } from "api/api.service"
import useGetPdfReport from "hooks/useGetPdfReport"

const COLUMNS = {
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    REQUESTED_QUANTITY: "Requested Quantity",
    STATUS: "Status",
    STATUS_UPDATE: "Status Update",
    QUANTITY_SUPPLIED: "Supplied Quantity",
    SUBTITUTED_ITEM_NAME: "Subsitute Name",
    REQUESTED_DATE: "Requested Date",
    AVAIALBLE_QUANTITY: "Available Quantity",
    QUANTITY_DELIVERED: "Deliver Quantity",
}
const PDF_TYPE = "Pullback"
const errorsData = [
    "Field is required",
    "Quantity can't be 0",
    "quantity exceeds",
]

const PullbackRequestItems = ({
    data = {},
    isSubmitting = false,
    isPullbackRequests = true,
    onSubmit = () => {},
    modalCloseHandler = () => {},
}) => {
    const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()
    const [itemsData, setItemsData] = useState([])
    const [tInput, setTInput] = useState("")
    let statusOptions = getSelectBoxOptionsFromConstants(
        InventoryReqItemsStatus
    ).filter(item => item.label != InventoryReqItemsStatus.Substitutes)

    useEffect(() => {
        let clone = [...data.items]
        clone = clone?.map(item => ({
            ...item,
            // inputQty: item.departmentQuantity > 1 && 1,
            inputQty: "",

            subsituteItemsOptions: null,
            isShowItemsSelectBox: false,
            selectedSubsituteItem: null,
            selectedStatusFromOptions: null,
            remainingQuantity: (
                item?.quantity - item?.quantitySupplied
            )?.toFixed(2),
            hasInputError: false,
            isDisableInputQty: true,
            isDisableItemsOptionsSelectbox: true,
            copyOfAvailableQuantity: item?.departmentQuantity,
            fetchingSubsituteItems: false,
            itemsOptionsSelectPlaceholder: "Select Item",
        }))
        setItemsData([...clone])
    }, [])

    const handleProvidedQuantityInput = (evt, targetObj, index) => {
        let indexOfTargetObj = index
        let inputQty = evt.target.value.replace(/[^0-9]*/g, "")
        let stateCopy = [...itemsData]
        stateCopy[indexOfTargetObj] = {
            ...targetObj,
        }

        if (inputQty == "") {
            console.log("there")
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                hasInputError: true,
                inputQty: inputQty,
                UPError: 0,
            }
            // return
        } else if (+inputQty == 0) {
            console.log("there")
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                hasInputError: true,
                inputQty: inputQty,
                UPError: 1,
            }
            // return
        } else if (
            +inputQty > targetObj.remainingQuantity ||
            +inputQty > targetObj.departmentQuantity
        ) {
            console.log("there")
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                hasInputError: true,
                inputQty: inputQty,
                UPError: 2,
            }
            // return
        } else {
            console.log("else is running")
            stateCopy[indexOfTargetObj] = {
                ...targetObj,
                hasInputError: false,
                // inputQty: Math.abs(inputQty),
                inputQty: inputQty,
            }
        }

        setItemsData(stateCopy)
    }

    const handleChangeStatus = (selectedOption, targetObj, index) => {
        let indexOfTargetObj = index
        let stateCopy = [...itemsData]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        switch (selectedOption.value) {
            case "Pending":
                refObj.selectedStatusFromOptions = { ...selectedOption }
                refObj.status = selectedOption.value
                refObj.inputQty = 0
                refObj.subsituteItemsOptions = null
                refObj.selectedSubsituteItem = null
                refObj.hasInputError = false
                refObj.isDisableInputQty = true
                refObj.isDisableItemsOptionsSelectbox = true
                refObj.isShowItemsSelectBox = false
                refObj.warehouseQuantity = refObj.copyOfAvailableQuantity

                setItemsData(stateCopy)

                return
            case "AllDelivered":
                if (refObj.status != InventoryReqItemsStatus.Substitutes) {
                    refObj.selectedStatusFromOptions = { ...selectedOption }
                    refObj.status = selectedOption.value
                    refObj.inputQty = refObj.remainingQuantity
                    refObj.hasInputError = false
                    refObj.isDisableInputQty = true
                    refObj.isDisableItemsOptionsSelectbox = true

                    setItemsData(stateCopy)
                }
                return

            case "PartialDelivered":
                if (refObj.status != InventoryReqItemsStatus.Substitutes) {
                    refObj.selectedStatusFromOptions = { ...selectedOption }
                    refObj.status = selectedOption.value
                    refObj.inputQty = ""
                    refObj.hasInputError = false
                    refObj.isDisableInputQty = false
                    refObj.isDisableItemsOptionsSelectbox = true

                    setItemsData(stateCopy)
                }

                return
            case "Substitutes":
                refObj.fetchingSubsituteItems = true
                getSubsitutedItems(refObj.itemsId)
                    .then(res => {
                        let subsituteItemsOptions = res?.data
                            ?.map(item => ({
                                ...item,
                                label: item.itemName,
                                value: item.id,
                                sku: item.skuNo,
                            }))
                            ?.filter(
                                option =>
                                    option?.quantity &&
                                    option?.quantity > 0 &&
                                    option
                            )

                        refObj.isShowItemsSelectBox = true
                        refObj.inputQty = ""
                        refObj.subsituteItemsOptions =
                            subsituteItemsOptions?.length
                                ? [...subsituteItemsOptions]
                                : null
                        refObj.selectedSubsituteItem = null
                        refObj.selectedStatusFromOptions = { ...selectedOption }
                        refObj.status = selectedOption.value
                        refObj.hasInputError = false
                        refObj.isDisableInputQty = true
                        refObj.isDisableItemsOptionsSelectbox = !Boolean(
                            subsituteItemsOptions?.length
                        )
                        refObj.itemsOptionsSelectPlaceholder =
                            subsituteItemsOptions?.length
                                ? "Select Item"
                                : "Not Available"

                        setItemsData(stateCopy)
                    })
                    .catch(err => {
                        // toast.error(err.message)
                    })
                    .finally(() => {
                        refObj.fetchingSubsituteItems = false
                    })
                return
        }
    }

    const getSubsitutedItems = async (id = "") => {
        if (!id) return

        try {
            let res = await GetInventoryItemById(id)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const handleItemsSelectboxChange = (option, targetObj, index) => {
        let indexOfTargetObj = index
        let stateCopy = [...itemsData]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        refObj.selectedSubsituteItem = option
        refObj.warehouseQuantity = option.quantity
        refObj.isDisableInputQty = false
        refObj.substituteId = option.value
        setItemsData(stateCopy)
    }

    const handleSubmit = () => {
        let toastId

        if (data.status == RequestStatus.COMPLETED) {
            modalCloseHandler()
            return
        }

        let rawData = [...itemsData]?.filter(
            obj => obj.status != "Pending" && obj.quantitySupplied == 0
        )

        if (!rawData.length) {
            toast.dismiss(toastId)
            toastId = toast.error("Kindly update the data !")
            return
        }

        if (rawData?.filter(item => item.inputQty == 0).length) {
            toast.error("Kindly add requested quanity !")
            return
        }

        if (rawData?.filter(item => item.hasInputError).length) {
            toast.error("Requested quanity is invalid!")
            return
        }

        let finalData = {
            id: data.id,
            departmentId: data.departmentId,
            items: [
                ...rawData?.map((obj, idx) => {
                    return {
                        id: obj.id,
                        departmentRequestId: obj.departmentRequestId,
                        status: obj.status,
                        quantity: obj.quantity,
                        quantitySupplied: Math.abs(obj.inputQty),

                        itemsId: obj.itemsId,
                        ...(obj?.selectedSubsituteItem?.value && {
                            substituteId: obj.substituteId,
                        }),
                    }
                }),
            ],
        }

        onSubmit(finalData)
    }
    console.log("data: ", itemsData)
    return (
        <>
            {data?.items?.length && (
                <div>
                    <h3 className="text-center inv-req-popup-heading">
                        Request Id : {data?.id || "-"}
                    </h3>
                    <DataTable
                        data={itemsData}
                        className="view-detail-inv-req-table department-req-items-table height-scroll-table"
                        tableClasses="pullback-detail-table"
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return data?.skuNo || "-"
                                },
                            },
                            {
                                title: COLUMNS.SUBTITUTED_ITEM_NAME,
                                render: data => {
                                    return data?.substitute?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_QUANTITY,
                                render: data => {
                                    return data?.quantity || "-"
                                },
                            },
                            {
                                title: COLUMNS.AVAIALBLE_QUANTITY,
                                render: data => {
                                    return data?.departmentQuantity || "-"
                                },
                            },
                            {
                                title: COLUMNS.QUANTITY_SUPPLIED,
                                render: data => {
                                    return data?.quantitySupplied
                                },
                            },
                            {
                                title: COLUMNS.REQUESTED_DATE,
                                render: data => {
                                    return convertDateTime({
                                        date: data.createdAt,
                                        customFormat:
                                            dateTimeFormat.casitaCutomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },
                            {
                                title: COLUMNS.STATUS,
                                render: (data, idx) => {
                                    return (
                                        <div className="d-flex align-items-center justify-content-evenly">
                                            <div
                                                className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                                                    data.status
                                                )}`}
                                            >
                                                {InventoryReqItemsStatus.getDisplayTextKey(
                                                    data.status
                                                )}
                                            </div>

                                            {data.quantitySupplied == 0 &&
                                                data.departmentQuantity > 0 && (
                                                    <div>
                                                        <AutoComplete
                                                            isSearchable={false}
                                                            placeholder="Select Status"
                                                            classNamePrefix="view-detail-select"
                                                            customStyles={
                                                                customStyles.ModalVD
                                                            }
                                                            options={statusOptions?.map(
                                                                item => ({
                                                                    ...item,

                                                                    ...(item.value ==
                                                                        InventoryReqItemsStatus.All_Delivered &&
                                                                        data.warehouseQuantity <
                                                                            data.remainingQuantity && {
                                                                            isDisabled: true,
                                                                        }),

                                                                    ...(item.value ==
                                                                        InventoryReqItemsStatus.Partial_Delivered &&
                                                                        data.remainingQuantity ==
                                                                            1 && {
                                                                            isDisabled: true,
                                                                        }),

                                                                    ...(item.value ==
                                                                        InventoryReqItemsStatus.Partial_Delivered &&
                                                                        data.warehouseQuantity ==
                                                                            0 && {
                                                                            isDisabled: true,
                                                                        }),
                                                                })
                                                            )}
                                                            value={
                                                                data.selectedStatusFromOptions
                                                            }
                                                            onChange={val =>
                                                                handleChangeStatus(
                                                                    val,
                                                                    data,
                                                                    idx
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            {data.isShowItemsSelectBox && (
                                                <div>
                                                    <AutoComplete
                                                        isDisabled={
                                                            data.isDisableItemsOptionsSelectbox
                                                        }
                                                        isLoading={
                                                            data.fetchingSubsituteItems
                                                        }
                                                        isSearchable={true}
                                                        placeholder={
                                                            data.itemsOptionsSelectPlaceholder
                                                        }
                                                        classNamePrefix="view-detail-select"
                                                        customStyles={
                                                            customStyles.ModalVD
                                                        }
                                                        options={data?.subsituteItemsOptions?.filter(
                                                            item =>
                                                                item.sku !=
                                                                data.skuNo
                                                        )}
                                                        value={
                                                            data.selectedSubsituteItem
                                                        }
                                                        onChange={val =>
                                                            handleItemsSelectboxChange(
                                                                val,
                                                                data,
                                                                idx
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                },
                            },

                            {
                                isHide: false,
                                title: COLUMNS.QUANTITY_DELIVERED,
                                render: (data, idx) => {
                                    if (data.quantitySupplied > 0) {
                                        return "-"
                                    } else if (!data.departmentQuantity > 0) {
                                        return "Not enough quantity"
                                    }
                                    return (
                                        <div>
                                            <TextInput
                                                onKeyDown={e =>
                                                    (e.key === "." ||
                                                        e.key === "-") &&
                                                    e.preventDefault()
                                                }
                                                disabled={
                                                    data.isDisableInputQty
                                                }
                                                value={data.inputQty}
                                                onChange={e =>
                                                    handleProvidedQuantityInput(
                                                        e,
                                                        data,
                                                        idx
                                                    )
                                                }
                                                className="view-detail-inv-req-p-ip-con"
                                                placeholder="Quantity"
                                                labelClass="d-none"
                                                inputClass="view-detail-inv-req-p-ip"
                                                min={0.1}
                                                step="0.0000001"
                                                max={`${data?.remainingQuantity}`}
                                                type="number"
                                            />
                                            {
                                                <span className="text-danger d-block input-error">
                                                    {data?.hasInputError &&
                                                        (data?.UPError === 2
                                                            ? `${
                                                                  errorsData[
                                                                      data
                                                                          ?.UPError
                                                                  ]
                                                              } ${
                                                                  data?.remainingQuantity
                                                              }`
                                                            : errorsData[
                                                                  data?.UPError
                                                              ])}
                                                </span>
                                            }
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                    <div className="text-center my-4">
                        <Button
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 btn-alignment"
                            title={<div>Done</div>}
                            isLoading={isSubmitting}
                            onClick={handleSubmit}
                        />
                        <Button
                            isLoading={fetchingReport}
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn mx-2 px-5 btn-alignment"
                            title={<div>Print Orders</div>}
                            onClick={() =>
                                handleGetPdfReport(data?.id, PDF_TYPE)
                            }
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default PullbackRequestItems
