import { useSelector } from "react-redux"

const useDepartmentConfig = () => {
    const store = useSelector(state => state)

    return {
        currentDepartment: store?.departmentConfig?.currentSelectedDepartment,
        currentDepartmentName:
            store?.departmentConfig?.currentSelectedDepartment?.department
                ?.departmentName,
        currentDepartmentId: Number(
            store?.departmentConfig?.currentSelectedDepartment?.department?.id
        ),
    }
}

export default useDepartmentConfig
