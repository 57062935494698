import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
// import { BreadCrumb } from "../../components/BreadCrumb"

import { AutoComplete } from "components/AutoComplete"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import InventoryRequestIcon from "../../../assets/images/inventory-request/InventoryRequestIcon.png"
import ActionEyeIcon from "../../../assets/images/inventory-request/action-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"

import customStyles from "./HeaderStatusStyling"
import ViewDetailsInventoryRequest from "./ViewDetailsDepartmentRequest"
import ViewDetailsPullBackRequest from "./ViewDetailsPullBackRequest"

import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { TextInput } from "components/TextInput"

import { useHistory } from "react-router-dom"

import { GetDepartmentInventoryRequestListing } from "../../../api/api.service"
import { getAppName, updateObjCheckBox } from "../../../utils/commonUtils"

//actions
import { updateModalItems } from "../../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

// importing data from file
import { OrderLists } from "./inventoryRequestData"

// importing status
import { DeptInvenReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { InventoryReqStatus } from "constants"
import { Refresh } from "components/Refresh"
import useDepartmentConfig from "hooks/useDepartmentConfig"

const COLUMNS = {
    REQUEST_NO: "Request No.",
    DATE_OF_REQUEST: "Requested Date",
    STATUS: "Status",
    ACTION: "Action",
}

const dataKey = "data"
const totalKey = "total"

const InventoryRequestDepartmentListing = () => {
    const { currentDepartmentId } = useDepartmentConfig()
    const history = useHistory()
    const [filters, setFilters] = useState(null)
    const [selectedData, setSelectedData] = useState([])
    const [modalToggle, setModalToggle] = useState(false)

    const {
        data,
        isFetching,
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        total,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetDepartmentInventoryRequestListing,
        params: {
            ...(filters?.value && { requestStatus: filters?.value }),
            departmentId: [currentDepartmentId],
        },
        deps: [filters, currentDepartmentId],
        dataKey: dataKey,
        totalKey: totalKey,
    })

    const handleSetDetailsDataAndModal = data => {
        setSelectedData(data)
        setModalToggle(true)
    }

    const handleCloseModal = () => {
        setSelectedData([])
        setModalToggle(false)
    }

    const handleSelectOption = option => {
        setFilters(option)
    }
    return (
        <React.Fragment>
            <div className="gt-business-accounts ">
                <Container fluid>
                    <Row>
                        <Col className="col-12 ">
                            <TableTopHeading
                                title={`Inventory Requests (${getAppName()})`}
                                iconImage={InventoryRequestIcon}
                                iconClass="inventory-req-icon"
                            />

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />

                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="fw-600 mb-0 table-main-heading">
                                            Requests
                                        </h5>
                                        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row ">
                                            <div className="status-search-con">
                                                <span className="status-label mx-2">
                                                    Status :
                                                </span>
                                                <AutoComplete
                                                    value={filters}
                                                    isClearable
                                                    onChange={val =>
                                                        handleSelectOption(val)
                                                    }
                                                    isSearchable={false}
                                                    placeholder="Select status"
                                                    customStyles={
                                                        customStyles.InventoryHeader
                                                    }
                                                    classNamePrefix="status-header-search"
                                                    options={[
                                                        {
                                                            label: "Open",
                                                            value: "Open",
                                                        },
                                                        {
                                                            label: "In Progress",
                                                            value: "InProgress",
                                                        },
                                                        {
                                                            label: "Completed",
                                                            value: "Completed",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <PageLimit
                                        pageSize={pageSize}
                                        changePageSizeHandler={onChangePageSize}
                                        rowLimit={pageSize}
                                        typoClasses="page-limit-typo"
                                        selectClasses="page-limit-select"
                                    />
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.REQUEST_NO,
                                                render: data => data.id,
                                            },
                                            {
                                                title: COLUMNS.DATE_OF_REQUEST,
                                                render: data =>
                                                    convertDateTime({
                                                        date: data.createdAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCutomDateFormat,
                                                        dateOnly: true,
                                                    }),
                                            },
                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                                                                data.status
                                                            )}`}
                                                        >
                                                            {InventoryReqStatus.getDisplayTextKey(
                                                                data.status
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTION,
                                                render: data => {
                                                    return (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() => {
                                                                handleSetDetailsDataAndModal(
                                                                    data
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    ActionEyeIcon
                                                                }
                                                            />
                                                        </span>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modalToggle}
                handleModalToggling={handleCloseModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <ViewDetailsInventoryRequest
                    modalCloseHandler={handleCloseModal}
                    data={selectedData}
                />
            </Modal>
        </React.Fragment>
    )
}

export default InventoryRequestDepartmentListing
