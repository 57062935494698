import { ME_SUCCESS, SET_TOKEN, ME_UPDATE } from "../actions"

const initialState = {
    isAuthenticated: false,
    user: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            }
        case ME_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    userAssignments: { ...action.payload },
                },
            }

        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token,
                },
            }
        default:
            return state
    }
}
