import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"

import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
    GetItemCatalogDetailsById,
    GetPdfReportOfItemCatalog,
} from "api/api.service"
import { downloadPdf } from "utils/downloadUtils"

const COLUMNS = {
    S_NO: "S.No.",
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    QUANTITY: "Quantity",
}

const ItemCatalogListing = ({ departmentId, departmentName }) => {
    const [fetchingReport, setFetchingReport] = useState(false)
    const [localData, setLocalData] = useState([])

    const currentUser = useSelector(state => state)
    // let currentDepartment = currentUser.userAuth.user
    // const currentDepartmentId =
    //     currentDepartment.userAssignments[0]?.department?.id || "-"
    // const currentDepartmentName =
    //     currentDepartment.userAssignments[0]?.department?.departmentName || "-"

    let currentDepartment =
        currentUser.departmentConfig.currentSelectedDepartment
    // const currentDepartmentId = currentDepartment?.department?.id || "-"
    // const currentDepartmentName =
    //     currentDepartment?.department?.departmentName || "-"

    const {
        isFetching,
        page,
        searchText,
        pageSize,
        data,
        total,
        request,
        handlePageClick,
        handleSearchChange,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetItemCatalogDetailsById,
        params: {
            departmentId: departmentId,
        },
        deps: [departmentId],
        id: departmentId,
        dataKey: "data",
        totalKey: "total",
    })

    useEffect(() => {
        if (!isFetching && data.length) {
            setLocalData(prev => [...prev, ...data])
        }
    }, [data])

    const handlePrintItemCatalog = async () => {
        const timeZone = -(new Date().getTimezoneOffset() / 60)
        const paramsBody = {
            offset: timeZone,
        }

        try {
            setFetchingReport(true)
            let res = await GetPdfReportOfItemCatalog(departmentId, paramsBody)

            await downloadPdf(res)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setFetchingReport(false)
        }
    }

    return (
        <>
            <h3 className="text-center inv-req-popup-heading">
                ITEM CATALOG
                <br />
                {departmentName}
            </h3>
            <DataTable
                loading={isFetching}
                data={localData}
                className="view-detail-inv-req-table height-scroll-table"
                // This code add scroll
                // tableClasses="detail-view-table"
                config={[
                    {
                        title: COLUMNS.S_NO,
                        render: data => {
                            return data?.itemsId || "-"
                        },
                    },

                    {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                            return data?.itemName || "-"
                        },
                    },

                    {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                            return data?.skuNo || "-"
                        },
                    },

                    {
                        title: COLUMNS.QUANTITY,
                        render: data => {
                            return data?.quantity || "-"
                        },
                    },
                ]}
            />
            {total > 0 && localData.length < total ? (
                <div className="text-center py-2">
                    <p
                        className="m-0 p-0 clickable-link-text "
                        onClick={() => handlePageClick(page + 1)}
                    >
                        Load more
                    </p>
                </div>
            ) : null}

            <div className="d-flex justify-content-center py-2">
                <Button
                    size="lg"
                    className="gt-btn-grad-primary table-bottom-btn btn-alignment"
                    title={<div>Print Catalog</div>}
                    onClick={handlePrintItemCatalog}
                    isLoading={fetchingReport}
                    disabled={total == 0}
                />
            </div>
        </>
    )
}

export default ItemCatalogListing
