import { injectBaseConstantMethods } from "./BaseConstants"

const InventoryReqStatus = {
  // OPEN_SERVER: "Open",
  OPEN: "Open",
  IN_PROGRESS: "InProgress",
  COMPLETED: "Completed",
}

const displayTextKeys = {
  // [InventoryReqStatus.OPEN_SERVER]: "Open",
  [InventoryReqStatus.OPEN]: "Open",
  [InventoryReqStatus.IN_PROGRESS]: "In Progress",
  [InventoryReqStatus.COMPLETED]: "Completed",
}

const labelClass = {
  // [InventoryReqStatus.OPEN_SERVER]: "bg-red",

  [InventoryReqStatus.OPEN]: "bg-red",
  [InventoryReqStatus.IN_PROGRESS]: "bg-orange",
  [InventoryReqStatus.COMPLETED]: "bg-green",
}

export default injectBaseConstantMethods(
  InventoryReqStatus,
  displayTextKeys,
  labelClass
)
