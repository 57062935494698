import { Label, Input } from 'reactstrap';
import React from 'react'

function Select(props) {
    const { placeholder= "", className="", options=[]} = props;
  return (
    <>
    <Label for="exampleSelect">{placeholder}</Label>
    <Input type="select" name="select" id="exampleSelect" placeholder='hi there' >
        {options.map((el, index) => <>
        <option key={index} value={el.value}>{el.name}</option>
      </>)}
      </Input>
      </>
  );
}

export default Select;