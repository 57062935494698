import React from "react"

import { DataTable } from "components/DataTable"

const COLUMNS = {
    SKU_NO: "SKU.No",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity / Global Quantity",
}

const ItemListing = ({
    productStockData = {},
    isFetchingProductStock = false,
}) => {
    return (
        <>
            <DataTable
                loading={isFetchingProductStock}
                data={productStockData.data}
                className="bg-header-color mt-3 view-item-detail height-scroll-table p-1"
                config={[
                    {
                        title: COLUMNS.SKU_NO,
                        className: "dashboard-view-item-details text-center",
                        render: data => data?.skuNo || "-",
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        className: "dashboard-view-item-details text-center",
                        render: data => data?.itemName || "-",
                    },
                    {
                        title: COLUMNS.QUANTITY,
                        className: "dashboard-view-item-details text-center",

                        render: obj =>
                            (
                                <div
                                    className={`wh-mang-global-quantity  ${productStockData.color}`}
                                >
                                    {obj?.warehousequantity || "0"} /{" "}
                                    {obj?.globalquantity || "0"}
                                </div>
                            ) || "-",
                    },
                ]}
            />
        </>
    )
}

export default ItemListing
