import { injectBaseConstantMethods } from "./BaseConstants"

const GyftsStatus = {
    PENDING: "pending",
    IN_TRANSIT: "in_transit",
    DELIVERED: "delivered",
    CANCELLED: "canceled",
}

const displayTextKeys = {
    [GyftsStatus.IN_TRANSIT]: "In Transit",
    [GyftsStatus.DELIVERED]: "Delivered",
    [GyftsStatus.PENDING]: "Pending",
    [GyftsStatus.CANCELLED]: "Cancelled",
}

const labelClass = {
    [GyftsStatus.IN_TRANSIT]: "intransit",
    [GyftsStatus.DELIVERED]: "delivered",
    [GyftsStatus.PENDING]: "pending",
    [GyftsStatus.CANCELLED]: "cancelled",
}



export default injectBaseConstantMethods(GyftsStatus, displayTextKeys, labelClass)





