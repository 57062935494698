import React from "react"

const TableTopHeading = props => {
    const { iconImage, title = "", iconClass, className = "" } = props
    return (
        <>
            <div className={`mb-2 px-0 ${className}`}>
                <div className="icon-img-con">
                    <img
                        src={iconImage && iconImage}
                        className={`img-general ${iconClass && iconClass}`}
                    />
                </div>
                <h1 className="table-outer-heading ms-2 mb-0 v-middle me-2 me-md-0">
                    {title && title}
                </h1>
            </div>
        </>
    )
}

export default TableTopHeading
