const AddNewRequestSelect= {
    container: (provided) => ({
        
        display: "inline-block",
        position: "relative",
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #CED4DA',
        borderRadius: '4px',
        // width: '130px',
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        // borderRadius: "5px",
        minHeight: "45px",
        borderColor: "#9d9d9d",
        // backgroundColor: "#eef9ff",
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
            borderColor: "#9d9d9d"
        }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          // backgroundColor: isFocused ? "#999999" : null,
          // color: "#333333",
          // "&:hover" : {
          //   backgroundColor: "#999999"
          // },
          
        //   "&:isActive" : {
        //     backgroundColor: "#999999"
        //   },
        //   "&:focusVisible" : {
        //     backgroundColor: "#999999"
        //   }
        };
      },
      menuPortal: base => ({ ...base, zIndex: 9999 })
 
}
export default AddNewRequestSelect