import { injectBaseConstantMethods } from "./BaseConstants"

const InventoryReqStatus = {
  PENDING: "Pending",
  ALL_DELIVERED: "all_delivered",
  PARTIAL_DEVLIVERED: "partial_delivered",
  SUSBSITUTE: "subsitute",
}

const displayTextKeys = {
  [InventoryReqStatus.PENDING]: "Pending",
  [InventoryReqStatus.ALL_DELIVERED]: "All Delivered",
  [InventoryReqStatus.PARTIAL_DEVLIVERED]: "Partial Delivered",
  [InventoryReqStatus.SUSBSITUTE]: "Subsitue",
}

const labelClass = {
  [InventoryReqStatus.PENDING]: "bg-red",
  [InventoryReqStatus.ALL_DELIVERED]: "bg-green",
  [InventoryReqStatus.PARTIAL_DEVLIVERED]: "bg-orange",
  [InventoryReqStatus.SUSBSITUTE]: "bg-blue",
}

export default injectBaseConstantMethods(
  InventoryReqStatus,
  displayTextKeys,
  labelClass
)
