import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Col, Row } from "reactstrap"

import { DataTable } from "../../../components/DataTable"
import { TextInput } from "../../../components/TextInput"
import { AutoComplete } from "../../../components/AutoComplete"
import { Button } from "../../../components/Button"
import { makeOptionsDisabled } from "../../../utils/commonUtils"
import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import {
    GetDepartmentsListing,
    GetItemCatalogDetailsById,
    PostAssignInventoryToDepartment,
    WarehouseInventoryListing,
} from "../../../api/api.service"
import Config from "../../../Config"
import { useSelector } from "react-redux"

const COLUMNS = {
    CHECK_BOXES: "",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    ASSIGNED_QUANTITY: "Assigned Quantity",
    CATALOG_QUANTITY: "Catalog Quantity",
    AVAILABLE_QUANTITY: "Available Quantity",
    ACTION: "Action",
}

const errorsData = [
    "",
    "field required",
    "value should be > 0",
    "quantity exceeds",
]

const handleStateSet = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
        return {
            ...prev,
            ...(keysObj && keysObj),
        }
    })
}

const AssignInventoryToDepartment = ({
    itemType,
    previousSelection,
    handleCloseModal,
}) => {
    let controller = new AbortController()
    let signal = controller.signal

    const state = useSelector(state => {
        return state
    })
    const warehouseId =
        state.userAuth.user.warehouseAssignments[0]?.warehouse?.id

    const [isAllSelected, setIsAllSelected] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedItemsData, setSelectedItemsData] = useState([
        // ...previousSelection,
    ])
    const [warehouseItemsListing, setWarehouseItemsListing] = useState({
        data: [],
        fetching: false,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })
    const [departmentListing, setDepartmentListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })

    const [departmentCatalogItemsListing, setDepartmentCatalogItemsListing] =
        useState({
            data: [],
            fetching: false,
            total: 0,
            page: 1,
            Q: "",
            isDataAvailable: false,
        })

    useEffect(() => {
        if (!filters?.department) return
        getWarehouseItemsListing(warehouseItemsListing.page)

        return () => {
            controller.abort()
        }
    }, [warehouseItemsListing.Q, filters])

    useEffect(() => {
        getDepartmentsListing(departmentListing.page)

        return () => {
            controller.abort()
        }
    }, [departmentListing.Q])

    useEffect(() => {
        if (!filters?.department) return
        getDepartmentCatalogItemsListing(departmentCatalogItemsListing.page)

        return () => {
            controller.abort()
        }
    }, [filters?.department])

    const handleDeleteItem = item => {
        const tempList = selectedItemsData.filter(el => el.id !== item.id)
        setSelectedItemsData(tempList)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        //  filterout the checkones

        let onlyChecked = selectedItemsData.filter(item => item.isChecked)

        let errorCheck = onlyChecked.filter(el => el.quantityError !== 0)
        if (errorCheck.length) {
            return toast.error("Please insert the valid data in all fields.")
        }

        if (!onlyChecked.length) {
            return toast.error("No item has been selected !")
        }

        //  check that the filtered data has input values and not more than available value

        let hasValues = onlyChecked.find(
            item =>
                item.requiredQuantity <= 0 ||
                item.requiredQuantity > item.availableQty
        )

        if (hasValues) {
            return toast.error(
                "Some items have 0 or have greater that available quantity !"
            )
        }

        let bodyData = {
            type: "Request",
            departmentId: filters?.department?.id,
            departmentRequestItems: [],
        }
        onlyChecked.forEach(el => {
            let tempObj = {}
            tempObj.itemsId = el.itemsId
            tempObj.quantity = el.requiredQuantity * 1
            tempObj.status = "AllDelivered"
            bodyData.departmentRequestItems.push(tempObj)
        })
        try {
            setIsSubmitting(true)
            const res = await PostAssignInventoryToDepartment(bodyData)
            toast.success(
                "Inventory has been assigned to station successfully!"
            )
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsSubmitting(false)
        }
    }
    const handleInputValidation = (e, id) => {
        let cloned = [...selectedItemsData]
        cloned.forEach(el => {
            if (el.id === id) {
                // const modifiedValue = e.target.value.replace(/[^0-9]*/g, "")
                const modifiedValue = e.target.value
                // .replace(/[^0-9.]+/g, "")

                el.requiredQuantity = modifiedValue
                if (e.target.value < 0) {
                    el.quantityError = 2
                }
                if (e.target.value == 0) {
                    el.quantityError = 1
                }

                if (e.target.value > 0 && e.target.value !== "") {
                    el.quantityError = 0
                }

                if (e.target.value > el.availableQty) {
                    el.quantityError = 3
                }
            }
        })
        setSelectedItemsData(cloned)
    }

    const handleSelectWarehouseItem = e => {
        const tempList = selectedItemsData.filter(el => el.skuNo == e.skuNo)
        if (!tempList.length) {
            let add = [...selectedItemsData, e]

            let sortedData = add.sort((a, b) => b.availableQty - a.availableQty)
            setSelectedItemsData(sortedData)
        }
    }

    const getWarehouseItemsListing = page => {
        handleStateSet(setWarehouseItemsListing, {
            fetching: true,
            // data: []
        })
        WarehouseInventoryListing(
            {
                // limit: Config.LIMIT,
                limit: 100,
                ...(warehouseItemsListing.Q && { Q: warehouseItemsListing.Q }),
                ...(filters?.department && {
                    departmentId: filters.department.id,
                }),
                ...{ ...itemType },
                page: page,
            },
            signal
        )
            .then(res => {
                setWarehouseItemsListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data.length == 100,
                    data: (() => {
                        let test = res.data.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.itemName,
                            isDisable: true,
                            requiredQuantity: "",
                            quantityError: 0,
                            isChecked: false,
                            availableQty: item.quantity,
                            catalogQty: item.catelogQuantity,
                        }))

                        let notPartOfThisDepartmentCatalog = test.filter(
                            item => !item.catelogItemStatus
                        )

                        return [...notPartOfThisDepartmentCatalog, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setWarehouseItemsListing, { fetching: false })
            })
    }

    const handleInitDeleteItem = (id, sku) => {
        warehouseItemsListing?.data.map(el => {
            if (el.skuNo === sku) {
                el.isDisabled = false
            }
        })
        handleDeleteItem(id)
    }

    const handleSearch = (value, callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const getDepartmentsListing = page => {
        handleStateSet(setDepartmentListing, { fetching: true, data: [] })
        GetDepartmentsListing(
            {
                limit: Config.LIMIT,
                ...(departmentListing.Q && { Q: departmentListing.Q }),
                page: page,
            },
            signal
        )
            .then(res => {
                setDepartmentListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length === Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.departmentName,
                        }))
                        return [...test, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setDepartmentListing, { fetching: false })
            })
    }

    const getDepartmentCatalogItemsListing = page => {
        handleStateSet(setDepartmentCatalogItemsListing, {
            fetching: true,
            data: page > 1 ? [...departmentCatalogItemsListing.data] : [],
        })
        GetItemCatalogDetailsById(
            {
                warehouseId: warehouseId,
                limit: Config.LIMIT,
                ...(departmentCatalogItemsListing.Q && {
                    Q: departmentCatalogItemsListing.Q,
                }),
                page: page,
            },
            signal,
            filters?.department?.id
        )
            .then(res => {
                setDepartmentCatalogItemsListing(prev => ({
                    ...prev,
                    fetching: false,
                    isDataAvailable: res?.data?.length == Config.LIMIT,
                    total: res.total,
                    data: (() => {
                        let alteredData = res?.data?.map(item => ({
                            ...item,
                            isDisable: true,
                            requiredQuantity: "",
                            quantityError: 0,
                            isChecked: false,
                            availableQty: item.warehouseQuantity,
                            catalogQty: item.quantity,
                        }))

                        let sortedData = alteredData.sort(
                            (a, b) => b.availableQty - a.availableQty
                        )
                        let allData = [...sortedData, ...prev.data]
                        setSelectedItemsData(allData)
                        return [...alteredData, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setDepartmentCatalogItemsListing, {
                    fetching: false,
                })
            })
    }

    const handleSelectDepartment = (name, option) => {
        setFilters({ ...filters, [name]: option })
    }

    const handleCheckBoxSelect = (data, ind) => {
        let clone = [...selectedItemsData]
        if (clone[ind].isChecked) {
            clone[ind].isChecked = false
            clone[ind].quantityError = ""
            clone[ind].requiredQuantity = ""
        } else {
            clone[ind].isChecked = true
            clone[ind].quantityError = 1
        }

        setSelectedItemsData(clone)
    }

    const handleSelectAll = () => {
        let afterChecked = selectedItemsData.map(item => ({
            ...item,
            ...(item.availableQty > 0 && { isChecked: !isAllSelected }),
        }))
        let audit = afterChecked.map(item => ({
            ...item,
            quantityError:
                item.isChecked && item.quantityError != ""
                    ? item.quantityError
                    : "",
            requiredQuantity: item.isChecked ? item.requiredQuantity : "",
        }))
        setIsAllSelected(prev => !prev)
        setSelectedItemsData(audit)
    }

    return (
        <div className="p-2">
            <h3 className="text-center inv-req-popup-heading">
                ASSIGN INVENTORY
            </h3>

            <Row>
                <Col sm="12" md="5" lg="5">
                    <div className="pt-3 pb-4  d-flex align-items-center">
                        <span
                            className="font-size-13 "
                            style={{
                                width: "140px",
                                fontWeight: "bold",
                                color: "black",
                            }}
                        >
                            Add Item
                        </span>
                        <div style={{ width: "500px" }}>
                            <AutoComplete
                                isDisabled={
                                    !filters?.department ||
                                    !selectedItemsData.length
                                }
                                handleBlur={() =>
                                    setWarehouseItemsListing(prev => {
                                        return {
                                            ...prev,
                                            page: 1,
                                            Q: "",
                                        }
                                    })
                                }
                                // handleScroll={() => getWarehouseItemsListing()}
                                onChange={handleSelectWarehouseItem}
                                onInputChange={val =>
                                    handleSearch(val, setWarehouseItemsListing)
                                }
                                placeholder="Search Item"
                                classNamePrefix="add-new-req"
                                customStyles={AutoCompleteStyling}
                                options={makeOptionsDisabled(
                                    previousSelection,
                                    warehouseItemsListing
                                )}
                                value={warehouseItemsListing?.data.filter(
                                    el => el.label === selectedItemsData
                                )}
                                isLoading={warehouseItemsListing?.fetching}
                                hideSelectedOptions
                            />
                        </div>
                    </div>
                </Col>

                <Col sm="12" md="2" lg="2"></Col>

                <Col sm="12" md="5" lg="5">
                    <div className="pt-3 pb-4  d-flex align-items-center">
                        <span
                            className="font-size-13 "
                            style={{
                                width: "200px",
                                fontWeight: "bold",
                                color: "black",
                            }}
                        >
                            Select Station
                        </span>
                        <div style={{ width: "500px" }}>
                            <AutoComplete
                                handleBlur={() =>
                                    setDepartmentListing(prev => {
                                        return {
                                            ...prev,
                                            page: 1,
                                            Q: "",
                                        }
                                    })
                                }
                                placeholder="All Departments"
                                classNamePrefix="add-new-req"
                                customStyles={AutoCompleteStyling}
                                onInputChange={val =>
                                    handleSearch(val, setDepartmentListing)
                                }
                                onChange={val =>
                                    handleSelectDepartment("department", val)
                                }
                                isLoading={departmentListing?.fetching}
                                options={departmentListing?.data}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            <DataTable
                loading={departmentCatalogItemsListing.fetching}
                data={selectedItemsData}
                className="view-detail-inv-req-table add-req-inv-con height-scroll-table w-100"
                tableClasses="create-order-im"
                config={[
                    {
                        title: (() => (
                            <div className="form-check">
                                <TextInput
                                    disabled={!selectedItemsData.length}
                                    onClick={() => handleSelectAll()}
                                    labelClass="mb-0"
                                    type="checkbox"
                                    inputClass="form-check-input-custom"
                                />
                            </div>
                        ))(),
                        render: (data, ind) => {
                            return (
                                <div className="form-check">
                                    <TextInput
                                        onClick={() =>
                                            handleCheckBoxSelect(data, ind)
                                        }
                                        labelClass="mb-0"
                                        type="checkbox"
                                        inputClass="form-check-input-custom"
                                        checked={
                                            data.isChecked &&
                                            data.availableQty > 0
                                        }
                                        disabled={data.availableQty == 0}
                                    />
                                </div>
                            )
                        },
                    },
                    ,
                    {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                            return data?.skuNo
                        },
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                            return data?.itemName
                        },
                    },
                    {
                        title: COLUMNS.AVAILABLE_QUANTITY,
                        render: data => {
                            return data.availableQty
                        },
                    },
                    {
                        title: COLUMNS.CATALOG_QUANTITY,
                        render: data => {
                            return data.catalogQty
                        },
                    },

                    {
                        title: COLUMNS.ASSIGNED_QUANTITY,
                        render: data => {
                            return (
                                <>
                                    <div>
                                        <TextInput
                                            disabled={
                                                !data.isChecked ||
                                                data.availableQty < 1
                                            }
                                            onChange={e =>
                                                handleInputValidation(
                                                    e,
                                                    data.id
                                                )
                                            }
                                            onKeyDown={e => {
                                                if (
                                                    e.key === "e" ||
                                                    e.key === "E"
                                                ) {
                                                    e.preventDefault()
                                                }
                                            }}
                                            className="view-detail-inv-req-p-ip-con me-4 me-md-5 pointer"
                                            placeholder="0"
                                            labelClass="d-none"
                                            type="number"
                                            min={0.1}
                                            max={data.availableQty}
                                            inputClass="view-detail-inv-req-p-ip"
                                            value={data.requiredQuantity}
                                            name="price"
                                            step="0.0000001"
                                        />
                                    </div>
                                    <span className="text-danger d-block error-msg-below-input error-message-aligning">
                                        {errorsData[data?.quantityError]}
                                    </span>
                                </>
                            )
                        },
                    },

                    {
                        title: COLUMNS.ACTION,
                        render: data => {
                            return (
                                <img
                                    onClick={e =>
                                        handleInitDeleteItem(data, data.skuNo)
                                    }
                                    className="view-detail-modal-delete"
                                    src={DeleteIcon}
                                />
                            )
                        },
                    },
                ]}
            />
            {departmentCatalogItemsListing?.total > 0 &&
            departmentCatalogItemsListing?.data?.length <
                departmentCatalogItemsListing?.total ? (
                <div className="text-center py-2">
                    <p
                        className="m-0 p-0 clickable-link-text "
                        onClick={() =>
                            getDepartmentCatalogItemsListing(
                                departmentCatalogItemsListing.page + 1
                            )
                        }
                    >
                        Load more
                    </p>
                </div>
            ) : null}

            <div className="text-center my-4 d-flex justify-content-center align-items-center">
                <Button
                    isLoading={isSubmitting}
                    size="lg"
                    className="gt-btn-grad-primary table-bottom-btn me-2 btn-alignment"
                    title={<div>Assign</div>}
                    onClick={handleSubmit}
                    disabled={!selectedItemsData.length}
                />
            </div>
        </div>
    )
}

export default AssignInventoryToDepartment
