import React from "react"
import { Input } from "reactstrap"
import { preventUnwantedInput } from "utils/formUtils"

const UsedCount = ({ total, value, max, onChange = () => {} }) => {
  return (
    <div className="d-flex align-items-center">
      <Input
        onKeyDown={e => preventUnwantedInput(e)}
        min={1}
        max={max}
        className="used-count-input"
        type="number"
        value={value}
        onChange={onChange}
      />
      <div>
        <p className="m-0 p-0">/ {total}</p>
      </div>
    </div>
  )
}

export default UsedCount
