import moment from "moment"

export const dateTimeFormat = {
    ddmmyyyy: "DD MMM, YYYY",
    casitaCutomDateFormat: "DD MMM, YYYY - HH:mm",
    appDateTimeFormat: "DD/MM/YYYY, HH:mm A",
    appDateFormat: "DD-MM-YYYY",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    apiDateFormat: "YYYY-MM-DD",
    gmtDateTimeFormat: "YYYY-MM-DD HH:mm",
    creationDateFormat: "DD MMMM, YYYY",
    apiTimeFormat: "HH:mm:ss",
    appTimeFormat: "HH:mm",
    payoutDateQuery: "YYYY-MM",
    timeFormat: "LT",
    dateWithTimeFormat: "MMM  Do, h:mm A",
    dateWithYearTimeFormat: "MMM  Do YYYY, h:mm A",
}

export const convertDateTime = ({
    to,
    date,
    dateOnly = false,
    customFormat = null,
}) => {
    const format = customFormat
        ? customFormat
        : dateOnly
        ? dateTimeFormat.apiDateFormat
        : dateTimeFormat.apiDateTimeFormat

    return moment(date).format(format)
}

export const convertTime = ({ tz, time, format }) => {
    if (tz === "GMT_TIME") {
        return moment(time, format).utc().format(format)
    } else if (tz === "LOCAL_TIME") {
        return moment.utc(time, format).local().format(format)
    }
}

export const convertDateTimeGMTFormat = date => {
    const startOf = () => {
        return moment(date)
            .startOf("day")
            .format(dateTimeFormat.gmtDateTimeFormat)
    }
    const endOf = () => {
        return moment(date)
            .endOf("day")
            .format(dateTimeFormat.gmtDateTimeFormat)
    }
    return {
        startOf,
        endOf,
    }
}

export const convertDateToInvoicePattern = date => {
    const getPreviousMonthDate = () => {
        return moment(date).subtract(1, "month").format("LL")
    }
    const getPreviousDayDate = () => {
        return moment(date).subtract(1, "day").format("LL")
    }
    return {
        getPreviousMonthDate,
        getPreviousDayDate,
    }
}

export const getTimeWithSeconds = (time, format, requiredFormat) => {
    return moment(time, format).format(requiredFormat)
}

export const getNearestTimeStampStr = date => {
    const isDaySame = moment().isSame(date, "day")
    const isYearSame = moment().isSame(date, "year")
    if (isDaySame) {
        return moment(date).format(dateTimeFormat.timeFormat)
    } else if (!isDaySame && isYearSame) {
        return moment(date).format(dateTimeFormat.dateWithTimeFormat)
    } else return moment(date).format(dateTimeFormat.dateWithYearTimeFormat)
}
