import React from "react"

import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import { RoutingTabs } from "../../../components/RoutingTabs"
import { APP_ROUTES } from "../../../helpers/routeHelpers"
import RequestsListing from "./RequestsListing"
import PurchaseOrdersListing from "./PurchaseOrdersListing"
import { TableTopHeading } from "components/TableTopHeading"
import { getAppName } from "utils/commonUtils"
import { BackButtonTableTop } from "components/BackButtonTableTop"

const RequestManagement = ({}) => {
    let CONFIG = {
        param: [],
        tabsConfig: [
            {
                title: "Purchase Requests",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS,
                component: <RequestsListing />,
            },
            {
                title: "Purchase Orders",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.REQUEST_MANAGEMENT_POS,
                component: <PurchaseOrdersListing />,
            },
        ],
    }

    return (
        <>
            <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3">
                <BackButtonTableTop />
            </div>
            <TableTopHeading
                title={`Request Management (${getAppName()})`}
                className="me-4 top-heading-typo"
                iconImage={InventoryRequestIcon}
                iconClass="inventory-req-icon"
            />
            <RoutingTabs
                containerClass="warehouse-nav-tabs"
                navClass="border-0"
                config={CONFIG}
                isPills
                isTabs={true}
                parentRoute={APP_ROUTES.REQUEST_MANAGEMENT}
            />
        </>
    )
}

export default RequestManagement
