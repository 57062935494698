import { fetchUtil } from "../utils/fetchUtil"
import { appendQueryParams } from "../utils/urlUtils"
import { store } from "../store"

const Get = ({
    url = "",
    params = {},
    isAuthorized = true,
    abortSignal = null,
    showToaster = true,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.getState().userAuth.user?.Token
    }

    return fetchUtil({
        url: appendQueryParams(url, params),
        token,
        abortSignal,
        showToaster,
    })
        .then(res => {
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

const Post = ({
    url,
    body = {},
    isAuthorized = true,
    abortSignal = null,
    showToaster = true,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.getState().userAuth.user?.Token
    }
    return fetchUtil({
        url,
        token,
        body: JSON.stringify(body),
        method: "POST",
        abortSignal,
        showToaster,
    })
        .then(res => {
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

const Put = ({
    url = "",
    body = {},
    isAuthorized = true,
    abortSignal = null,
    showToaster = true,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.getState().userAuth.user?.Token
    }
    return fetchUtil({
        url,
        token,
        body: JSON.stringify(body),
        method: "PUT",
        abortSignal,
        showToaster,
    })
        .then(res => {
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

const Delete = ({
    url = "",
    isAuthorized = true,
    abortSignal = null,
    showToaster = true,
}) => {
    let token = null
    if (isAuthorized) {
        token = store.getState().userAuth.user?.Token
    }
    return fetchUtil({
        url,
        token,
        method: "DELETE",
        abortSignal,
        showToaster,
    })
        .then(res => {
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

export { Get, Post, Put, Delete }
