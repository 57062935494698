import { injectBaseConstantMethods } from "./BaseConstants"

const TeammateStatus = {
  VERIFIED: "verified",
  INVITED: "invited",
  SUSPENDED: "suspended",
}

const displayTextKeys = {
  [TeammateStatus.VERIFIED]: "Verified",
  [TeammateStatus.INVITED]: "Invited",
  [TeammateStatus.SUSPENDED]: "Suspended",
}

const labelClass = {
  [TeammateStatus.VERIFIED]: "verified",
  [TeammateStatus.INVITED]: "invited",
  [TeammateStatus.SUSPENDED]: "suspended",
}

export default injectBaseConstantMethods(
  TeammateStatus,
  displayTextKeys,
  labelClass
)
